
<div *ngIf="!token" class="container row my-5">
  <h1>¡Regístrate o inicia sesión para continuar!</h1>
  <br>
  <h2>Login o registro</h2>
  <form [formGroup]="contactForm">
    <p>
      <mat-form-field
        appearance="outline"
        class="controles-container botonfull"
      >
        <mat-label>Usuario</mat-label>
        <input
          matInput
          type="text"
          placeholder="Ingresa tu usuario"
          formControlName="email"
        />
      </mat-form-field>
    </p>
    <p>
      <mat-form-field
        appearance="outline"
        class="controles-container botonfull"
      >
        <mat-label>Contraseña</mat-label>
        <input
          matInput
          [type]="hide ? 'password' : 'text'"
          placeholder="Ingresa tu contraseña"
          formControlName="password"
        />
        <span
          mat-icon-button
          matSuffix
          (click)="hide = !hide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
        >
            <mat-icon color="accent">{{
              hide ? "visibility_off" : "visibility"
            }}</mat-icon>
          </span>
      </mat-form-field>
    </p>
    <mat-error *ngIf="error">
      {{ error }}
    </mat-error>
    <p></p>
    <div class="row justify-content-between">
      <div class="col-auto">
        <button
          type="submit"
          mat-flat-button
          color="primary"
          [disabled]="!contactForm.valid"
          class="controles-container"
          (click)="onLogin()"
        >
          Login
        </button>
      </div>
      <div class="col">
        <button
          type="submit"
          mat-flat-button
          color="primary"
          [disabled]="!contactForm.valid"
          class="controles-container"
          (click)="register()"
        >
          Register
        </button>
      </div>
    </div>
  </form>
</div>

<div *ngIf="token" class="row my-5">
  <h2>Dirección de envío</h2>
  <form [formGroup]="addressCheckOut">
    <div class="row">
      <div class="col-md-8">
        <div class="row g-3 my-2">
          <div class="col">
            <input type="text" class="form-control" placeholder="Nombre Completo" aria-label="Nombre completo" formControlName="name" required>
            <span *ngIf="this.addressCheckOut.get('name')?.errors?.['required']">
                  El nombre es requerido
           </span>
          </div>
          <div class="col">
            <input type="text" class="form-control" placeholder="Telefono" aria-label="Telefono" formControlName="phone">
          </div>
        </div>
        <div class="row g-3 my-2">
          <div class="col">
            <input type="text" class="form-control" placeholder="Calle" aria-label="Calle" formControlName="street">
          </div>
          <div class="col">
            <input type="text" class="form-control" placeholder="Numero" aria-label="Numero" formControlName="number">
          </div>
          <div class="col">
            <input type="text" class="form-control" placeholder="Colonia" aria-label="Colonia" formControlName="colony">
          </div>
        </div>
        <div class="row g-3 my-2">
          <div class="col">
            <input type="text" class="form-control" placeholder="Municipio" aria-label="Municipio" formControlName="municipality">
          </div>
          <div class="col">
            <input type="text" class="form-control" placeholder="Estado" aria-label="Estado" formControlName="state">
          </div>
        </div>
        <div class="row g-3 my-2">
          <div class="col">
            <input type="number" class="form-control" placeholder="Postal Code" aria-label="Postal Code" formControlName="zipCode">
          </div>
          <div class="col">
            <select id="inputState" class="form-select"  formControlName="country">
              <option value="" disabled selected>Selecciona un país</option>
              <option value="Mexico" selected>México</option>
              <!--option>Usa</option-->
            </select>
          </div>

        </div>
        <!--div class="row mb-3">
          <div class="col">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="saveinfo">
              <label class="form-check-label" for="saveinfo">
                Save this information for next time
              </label>
            </div>
          </div>
        </div-->
        <div class="my-3">
          <button type="button" class="btn btn-bg " (click)="regresar()" style="background-color: #ff4d4d; color: white; border: none; cursor: pointer; transition: background-color 0.3s ease;">
            Regresar
          </button>
          <button type="button" class="btn btn-bg " [disabled]="!addressCheckOut.valid" (click)="personalData()">
            Continuar con la compra
          </button>
        </div>
      </div>
      <div class="col-md-4">

      </div>
    </div>
  </form>
</div>
