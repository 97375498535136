<ng-container *ngIf="productList && productList.length > 0">
  <article
    class="card shadow-sm card-product-list"
    *ngFor="
      let item of productList
        | filter : searchKey : 'product'
        | paginate : { itemsPerPage: 5, currentPage: p }
    "
  >
    <div class="row no-gutters">
      <aside class="col-md-3 p-2" (click)="viewProductDetail(item)">
        <a class="img-wrap">
          <img
            class="img-fluid card-img-top"
            src="{{ item.coverImage }}"
            alt="{{ item.product }}"
          />
        </a>
      </aside>
      <div class="col-md-6" (click)="viewProductDetail(item)">
        <div class="info-main">
          <h5 class="card-title">
            {{ item.product }}
          </h5>
          <!--<div class="rating-wrap mb-3">
              <ul class="rating-stars">
                  <li>
                      <bar-rating [(rate)]="item.rating.rate" [max]="5" [readonly]="true"></bar-rating>
                      <span>({{item.rating.count}})</span>
                  </li>
              </ul>
          </div>-->

          <!--p class="crop-text-2">{{ item.description }}</p-->
          <p class="crop-text-2"><span [innerHTML]="getTruncatedDescription(item.description)"></span></p>
        </div>
      </div>

      <aside class="col-sm-3">
        <div class="info-aside">
          <div class="price-wrap">
            <span class="price h5"> {{ item.priceShop | currency }} </span>

              <del class="price-old" *ngIf="item.undiscountedPrice > item.priceShop">
                {{ item.undiscountedPrice | currency }}
              </del>
            
          </div>
          <!--p class="text-success">Free shipping</p-->
          <br />
          <!--p>
            <button class="btn btn-bg w-100 mb-2" (click)="addToCart(item.id)">
              <i class="bi bi-cart-plus"></i> Add to Cart
            </button>
            <button href="#" class="btn btn-light w-100">
              <i class="bi bi-suit-heart-fill"></i>
              Add to wishlist
            </button>
          </p-->
        </div>
      </aside>
    </div>
  </article>
</ng-container>

<div class="col-md-5 mx-auto my-4">
  <ng-container *ngIf="productList && productList.length > 0">
    <pagination-controls (pageChange)="p = $event"></pagination-controls>
  </ng-container>
</div>
