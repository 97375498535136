import { Component, OnInit, inject, Input } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {UserService} from '../service/UserService';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { FormsModule } from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import { DataService } from '../service/data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Order } from '../shared/models/order';
//import {Product, SizePriceLts} from "../../shared/models/product";


@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrl: './payments.component.scss'
})
export class PaymentsComponent  implements OnInit  {

  //CARGAR ARCHIVO
  selectedFile: File | null = null;
  imageUrl: string | null = null;
  origin: string  = 'ecommerce-camben-comprobate-pago';
  uploading: boolean = false;

  granTotal: number = 0;
  //valor a copiar
  value: string = '014580606068618551';
  tooltipMessage: string = 'Copiar clave interbancaria';
  isClicked: boolean = false;
  isCopied: boolean = false;
  tooltipDisabled: boolean = false;

  private _snackBar = inject(MatSnackBar);

  randomNumber: any;
  addressCheckOut: any;
  name: string | null = null;

  whatsappUrl: string = 'https://api.whatsapp.com/send?phone=528110760979&text=&source=&data=';

  selectedPaymentMethod: string = '';

  selected = 'Selecciona una forma de pago';

  isProcessingOrder: boolean = false; // Nueva variable de estado

  constructor(private router: Router,
              private userService: UserService,
              private data: DataService) { }


  ngOnInit(): void {
    this.addressCheckOut = localStorage.getItem('addressCheckOut');
    console.log('token en checkout:', this.addressCheckOut);

    this.name =  this.addressCheckOut.name;
    console.log('nombre:', this.addressCheckOut.name);

    // Obtener el valor del localStorage
    const usuario = localStorage.getItem('addressCheckOut');
    if (usuario) {
      // Parsear la cadena JSON a un objeto JavaScript
      const addressObject = JSON.parse(usuario);
      // Acceder al campo name
      this.name = addressObject.name;
      console.log('Name:', this.name);


      // Reducir el nombre a 20 caracteres
      if (this.name && this.name.length > 30) {
        this.name = this.name.substring(0, 30);
        console.log('Nombre reducido:', this.name);
      }

    } else {
      console.log('No data found in localStorage');
    }


    this.generateRandomNumber()

    this.granTotal = JSON.parse(localStorage.getItem('granTotal') || '0');


  }

    paymentForm = new FormGroup({
      paymentMethod: new FormControl('', Validators.required)
    });

  generateRandomNumber(): number {
    // Genera un número aleatorio de 6 dígitos
    const randomNumberA = Math.floor(100000 + Math.random() * 900000);
    console.log('numero aleatorio: ', randomNumberA);
    this.randomNumber = randomNumberA;
    return randomNumberA;
  }

  /*onFileSelected(event: any): void {
    const file: File = event.target.files[0];

    if (file) {
      console.log(`Selected file: ${file.name}`);

      // Example of reading the file content
      const reader = new FileReader();
      reader.onload = (e: any) => {
        console.log('File content:', e.target.result);
        // Here you can handle the file content
      };
      reader.readAsText(file); // or readAsDataURL, readAsArrayBuffer, etc.
    }
  }*/

/*onFileSelected(event: Event): void {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      this.selectedFile = fileInput.files[0];
       console.log('File content:', this.selectedFile);
    }
  }*/

 onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    if (this.selectedFile) {
      this.uploadImage();
    }
  }

  uploadImage(): void {
    if (this.selectedFile) {
      this.uploading = true;
      this.data.uploadImage(this.selectedFile, this.origin).subscribe(
        (response) => {
          this.uploading = false;
          this.imageUrl = response.data.url;
           this.openSnackBar("Imagen cargada con exito", "¡Confirmar pedido!");
          console.info('url uploading image:', this.imageUrl);
        },
        (error) => {
           this.uploading = false;
            this.openSnackBar("falla en la carga de imagen", "¡Inténtalo nuevamente!");
          console.error('Error uploading image:', error);
        }
      );
    }
  }

  onPaymentMethodChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedPaymentMethod = selectElement.value;
  }

  /*confirmPedido(): void {
    //this.openSnackBar("Pedido confirmado",);
    console.info('confirma pedidos');
    this.router.navigate(['/pedidos']);
    }*/
confirmPedido() {
  this.isProcessingOrder = true; // Activa el estado de carga

const currentDate = new Date().toISOString().replace(/\.\d{3}Z$/, '000000Z');
console.log(currentDate); // Ejemplo: "2024-10-05T10:45:15.000000Z"

  try {
    // Recuperar información de localStorage
    const addressCheckOut = JSON.parse(localStorage.getItem('addressCheckOut') || '{}');
    const cartItems = JSON.parse(localStorage.getItem('cartItems') || '[]');
    const cliente = JSON.parse(localStorage.getItem('cliente') || '{}');
    const granTotal = JSON.parse(localStorage.getItem('granTotal') || '0');
    const subtotal = JSON.parse(localStorage.getItem('subtotal') || '0');
    const envio = JSON.parse(localStorage.getItem('envio') || '0');

   // Determinar el costo de envío
    const shippingCost = granTotal > 799 ? 285 : 0;

     // Determinar el subtotal de envío
   // const subtotal = granTotal > 799 ? 285 : 0;

// Recuperar datos almacenados en sessionStorage
    const createAtPlus5BusinessDays = sessionStorage.getItem('createAtPlus5BusinessDays') || 'N/A';
    const createAtPlus20BusinessDays = sessionStorage.getItem('createAtPlus20BusinessDays') || 'N/A';


    // Crear la estructura del cuerpo del request
    const order: Order = {
      user: cliente.uid || '',
      email: cliente.email || "email@default.com",
      name: addressCheckOut.name || "Nombre por defecto",
      phone: addressCheckOut.phone || "0000000000",
      street: addressCheckOut.street || "Calle",
      numberHome: addressCheckOut.number || "000",
      colony: addressCheckOut.colony || "Colonia",
      city: addressCheckOut.municipality || "Municipio",
      state: addressCheckOut.state || "Estado",
      zipCode: addressCheckOut.zipCode || "00000",
      country: addressCheckOut.country || "Mexico",
      status: "Orden confirmada",
      deliveryDate: "2024-09-15T05:08:11.831000000Z",
      createAtPlus5BusinessDays: createAtPlus5BusinessDays,
      createAtPlus20BusinessDays: createAtPlus20BusinessDays,
      observations: "Observaciones sobre el pedido",
      shippingMethod: "Envio",
      shippingType: "Estandar",
      shippingCost: envio,
      parcelName: "-",
      guideNumber: "-",
      total: granTotal,
      subtotal: subtotal,
      discount: 0,
      tax: 0,
      totalPayment: granTotal,
      urlFile: "URL de archivo detalle de pedido",
      products: cartItems,
      pay: [
        {
         // id: "1",
          methodPay: "Transferencia",
          amount: granTotal.toString(),
          status: "Validando pago",
          concepto: `${this.name}-${this.randomNumber}`,
          //concepto: "Hola pedido",
          description: "Validando la transferencia",
          urlPay: this.imageUrl || '',
          comentary: "Comentarios sobre el pago"
        }
      ],
      origin: "ecommerce-camben"
    };

    this.data.createOrder(order).subscribe(response => {
      console.log('Order created successfully:', response);

      // Vaciar el carrito después de confirmar el pedido
      this.data.clearCart();

      localStorage.removeItem('cartItems');
      localStorage.removeItem('granTotal');
      this.router.navigate(['/pedidos']);
      // Refrescar la página completa y redirigir a la sección de pedidos
      window.location.href = '/pedidos';
    }, error => {
      console.error('Error creating order:', error);
      this.isProcessingOrder = false;
      // Aquí puedes añadir más lógica para manejar el error
    });
  } catch (error) {
    console.error('Error processing order:', error);
    this.isProcessingOrder = false;
    // Manejo de errores
  }
}


 onCopy() {
     this.isClicked = true;
     this.isCopied = true; // Cambia el color del ícono a verde
     this.tooltipMessage = '¡Copiado!';
     this.tooltipDisabled = false; // Habilita el tooltip de "¡Copiado!"

     setTimeout(() => {
       this.isClicked = false;
       // Mantiene el ícono verde permanentemente después de la copia
     }, 200); // Tiempo para la animación de agrandamiento
   }

   onHover() {
     if (!this.isCopied) {
       this.tooltipMessage = 'Copiar clave interbancaria';
       this.tooltipDisabled = false; // Habilita el tooltip en hover
     }
   }

   resetIconColor() {
     if (!this.isClicked && !this.isCopied) {
       this.tooltipDisabled = true; // Deshabilita el tooltip al salir del botón
     }
   }

   resetTooltip() {
     this.tooltipMessage = 'Copiar clave interbancaria';
     this.tooltipDisabled = true; // Deshabilita el tooltip
   }

openSnackBar(message: string, action: string): void {
  const snackBarRef = this._snackBar.open(message, action, {
                                             duration: 1000,
                                           });
  snackBarRef.onAction().subscribe(() => {
    // Llamada al servicio de confirmación de pedido
    this.confirmPedido();
  });
}


  regresar(): void {
    this.router.navigate(['/secure-checkout']);
   }
}
