import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {DataService} from '../../service/data.service';
import {Product, SizePriceLts} from "../../shared/models/product";

import { Lightbox } from 'ngx-lightbox';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {

    // Definir las propiedades
    maxCreateAt: string | null = null;
    createAtPlus5BusinessDays: string | null = null;
    createAtPlus20BusinessDays: string | null = null;

  //generar numero randon para id de idTabla
    randomNumber: any;

  //mensajes de error
    showProductError: boolean = false;
    showSizeError: boolean = false;
   // showColorError: boolean = false;


  public getParam: any;
  product: Product | undefined;
  @Output() addItems: EventEmitter<any> = new EventEmitter();

  //---calificacion de productos
  stars: number[] = [];
  rating: number = 0; // Asegúrate de que `rating` esté definido y sea un número

  //--carga de imagenes
   imagen: any[] = [];

  albums: { src: string, caption: string, thumb: string, type: string }[] = [];

  urlVideos: { src: string, caption: string, thumb: string, type: string }[] = [];

  images: string[] = ['x', 'x'];
    isLightboxOpen = false;
     selectedImage: any = null;

     isHovered = false;

      videos: string[] = [
          'https://www.youtube.com/shorts/4dpxDvwK_0U',
          'https://www.youtube.com/shorts/4dpxDvwK_0U'
        ];

 videoUrl: string = 'https://www.youtube.com/embed/4dpxDvwK_0U';
  safeUrl!: SafeResourceUrl;
  urlSize: string = '';



//--color de la ropa
 /*colors: string[] = ['Red', 'Blue'];
  selectedColor: string | null = null;
*/

//colores
 /*colors: { name: string; hex: string }[] = [
    { name: 'Red', hex: '#FF0000' },
    { name: 'Blue', hex: '#0000FF'},
     { name: 'Amarillo', hex: '#edef00'},
  ];*/

  colors: { name: string; hex: string }[] = [];

  selectedColor: { name: string; hex: string } | null = null;

  selectColor(color: { name: string; hex: string }): void {
    this.selectedColor = color;
  }

newSize: SizePriceLts = {
    id: '',
    promoPrice: '',
    size: '',
    price: 0,
    lastUpdate: new Date(),
    namePerson: '',
    position: '',
    bloodType: '',
    claseInProgress: '',
    club: '',
    yearsService: '',
    stock: 0,
    priceSalesPerson: '',
    createAt: new Date(),
  };


// Método para resetear los valores de newSize
  resetNewSize(): void {
    this.newSize = {
      id: '',
      promoPrice: '',
      size: '',
      price: 0,
      lastUpdate: new Date(),
      namePerson: '',
      position: '',
      bloodType: '',
      claseInProgress: '',
      club: '',
      yearsService: '',
      stock: 0,
      priceSalesPerson: '',
      createAt: new Date(),
    };
  }

//tallas
  sizePriceLts: SizePriceLts[] = [
    // Your size and price data
  ];

  selectedSize: SizePriceLts | null = null;

  selectSize(size: SizePriceLts): void {
     console.log('Talla seleccionada:', size);
      this.selectedSize = size;
      console.log('Tamaño seleccionado actualizado:', this.selectedSize)
  }

  //cantidad
  productQuantity: number = 1;

  constructor(private route: ActivatedRoute, private data: DataService,
    private lightbox: Lightbox,
    private _lightbox: Lightbox,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
    private router: Router) {
  }



  ngOnInit(): void {

    //variables de fechas

      // Recuperar datos almacenados en sessionStorage
      const storedMaxCreateAt = sessionStorage.getItem('maxCreateAt');
      const storedCreateAtPlus5 = sessionStorage.getItem('createAtPlus5BusinessDays');
      const storedCreateAtPlus20 = sessionStorage.getItem('createAtPlus20BusinessDays');

      // Asignar los datos recuperados a las propiedades del componente si existen
      if (storedMaxCreateAt) {
        this.maxCreateAt = storedMaxCreateAt;
      }
      if (storedCreateAtPlus5) {
        this.createAtPlus5BusinessDays = storedCreateAtPlus5;
      }
      if (storedCreateAtPlus20) {
        this.createAtPlus20BusinessDays = storedCreateAtPlus20;
      }

    //lista de productos

    if (this.sizePriceLts.length > 0) {
      this.selectedSize = this.sizePriceLts[0];
    }

     this.route.paramMap.subscribe(params => {
        const state = window.history.state;

        if (state && state.product) {
          this.loadProduct(state.product);
          console.log('colors: ', this.colors );
          if (this.colors.length > 0) {
                  this.selectColor(this.colors[0]); // Selecciona el primer color por defecto
          }
        } else {
          const storedProduct = localStorage.getItem('selectedProduct');
          if (storedProduct) {
            this.loadProduct(JSON.parse(storedProduct));
            if (this.colors.length > 0) {
                    this.selectColor(this.colors[0]); // Selecciona el primer color por defecto
            }

          } else {
            console.error('No se encontró información del producto');
            // Lógica alternativa, como cargar el producto por ID usando un servicio
          }
        }
      });

    this.star();
    this.initializeAlbums();

    // Set the first image as the default selected image
    if (this.albums.length > 0) {
      this.selectedImage = this.albums[0];
    }

   this.safeUrl = this.sanitizeUrl(this.videoUrl);
  }

//cambiar de idioma
// Función para traducir los meses
  translateMonth(dateString: string): string {
    const date = new Date(dateString);
    const monthsInSpanish = [
      'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio',
      'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
    ];
    const day = date.getDate();
    const month = monthsInSpanish[date.getMonth()];
    return `${day} ${month}`;
  }

  loadProduct(product: Product): void {
    this.product = product;
    this.rating = product.qualification;
    this.images = product.images;
    this.videos = product.urlVideo;
    this.sizePriceLts = product.sizePriceLts;
    this.urlSize = product.urlSize;

    this.colors = product.colors;

    /*if (Array.isArray(product.colors)) {
      this.colors = product.colors;
    } else {
      this.colors = [product.colors]; // Convertir en array si es un solo objeto
    }*/

    console.log('Producto cargado:', this.product);
  }

  increaseQuantity() {
    if (this.productQuantity < 1000) {
      this.productQuantity++;
      console.log('lista de productos detalle campos: ', this.productQuantity );
    }
  }

  decreaseQuantity() {
    if (this.productQuantity > 1) {
      this.productQuantity--;
      console.log('lista de productos detalle campos: ', this.productQuantity );
    }
  }


/*
  selectColor(color: string): void {
    this.selectedColor = color;
  }*/

//--se usa para cargar videos
  sanitizeUrl(url: string): SafeResourceUrl {
     return this.sanitizer.bypassSecurityTrustResourceUrl(url);
   }


  star() {
    const fullStars = Math.floor(this.rating); // Número entero de estrellas llenas
    const fractionalStar = this.rating - fullStars; // Parte decimal de la calificación
    this.stars = Array(5).fill(0).map((_, i) => {
      if (i < fullStars) return 1; // Estrella llena
      if (i === fullStars && fractionalStar > 0) return 0.5; // Estrella parcialmente llena
      return 0; // Estrella vacía
    });
  }

//agregar al carrito
  /*addCart() {
    if (!this.product) {
      console.error('No hay producto seleccionado');
      return;
    }

    if (!this.selectedSize) {
      console.error('Por favor, selecciona una talla');
      return;
    }

    if (!this.selectedColor) {
      console.error('Por favor, selecciona un color');
      return;
    }

    const cartItem = {
      productId: this.product.id,
      productName: this.product.product,
      brand: this.product.brand,
      model: this.product.model,
      size: this.selectedSize.size,
      price: this.selectedSize.price,
      color: this.selectedColor.name,
      quantity: this.productQuantity,
      image: this.selectedImage ? this.selectedImage.src : this.product.coverImage,
    };

    // Aquí puedes emitir el evento o llamar a un servicio para agregar el item al carrito
    this.addItems.emit(cartItem);

    this.data.addProductToCart(cartItem);

    console.log('Producto añadido al carrito:', cartItem);
    // Aquí podrías mostrar un mensaje de éxito al usuario
  }*/
/*
addCart() {
  if (!this.product) {
    console.error('No hay producto seleccionado');
    return;
  }

  if (!this.selectedSize) {
    console.error('Por favor, selecciona una talla');
    return;
  }

  if (!this.selectedColor) {
    console.error('Por favor, selecciona un color');
    return;
  }

  const cartItem: Product = {
    id: this.product.id,
    product: this.product.product,
    brand: this.product.brand,
    model: this.product.model,
    description: this.product.description,
    detailProduct: this.product.detailProduct,
    coverImage: this.selectedImage ? this.selectedImage.src : this.product.coverImage,
    images: this.product.images,
    urlVideo: this.product.urlVideo,
    urlSize: this.product.urlSize,
    qualification: this.product.qualification,
    minPrice: this.product.minPrice,
    maxPrice: this.product.maxPrice,
    sizePriceLts: this.product.sizePriceLts,
    selectedSize: this.selectedSize,
    selectedColor: this.selectedColor,
    quantity: this.productQuantity
  };


  // Llamar al servicio para agregar el item al carrito
  this.data.addProductToCart(cartItem);

  // Emitir el evento
  this.addItems.emit(cartItem);

  console.log('Producto añadido al carrito:', cartItem);
  // Aquí podrías mostrar un mensaje de éxito al usuario
}*/

addCart() {
  this.showProductError = false;
    this.showSizeError = false;
   // this.showColorError = false;

    if (!this.product) {
      this.showProductError = true;
      return;
    }

    if (!this.selectedSize) {
      this.showSizeError = true;
      return;
    }

  /*  if (!this.selectedColor) {
      this.showColorError = true;
      return;
    }*/

  this.generateRandomNumber();


  // Actualizar el array sizePriceLts con los datos de selectedSize
    if (this.selectedSize) {
      // Aquí reemplazamos los 3 campos en sizePriceLts
      this.selectedSize.namePerson = this.newSize.namePerson || '';  // Nombre de la persona
      this.selectedSize.position = this.newSize.position || '';  // Posición
      this.selectedSize.bloodType = this.newSize.bloodType || '';  // Tipo de sangre
      this.selectedSize.claseInProgress = this.newSize.claseInProgress || '';  // clase cursando
      this.selectedSize.club = this.newSize.club || '';  // nombre del club
      this.selectedSize.yearsService = this.newSize.yearsService || '';
    }

  const cartItem: Product = {
    ...this.product,  // Esto copiará todas las propiedades existentes del producto
    idTable: this.randomNumber,

    selectedSize: this.selectedSize,
    selectedColor: this.selectedColor,
    quantity: this.productQuantity,
    coverImage: this.selectedImage ? this.selectedImage.src : this.product.coverImage,
  };


  // Llamar al servicio para agregar el item al carrito
  this.data.addProductToCart(cartItem);

  // Emitir el evento
  this.addItems.emit(cartItem);

  console.log('Producto añadido al carrito:', cartItem);
  // Aquí podrías mostrar un mensaje de éxito al usuario

  this.router.navigate(['/cart']);
}

//genera numero aleatorio para id de tabla
  generateRandomNumber(): number {
    // Genera un número aleatorio de 6 dígitos
    const randomNumberA = Math.floor(100000 + Math.random() * 900000);
    console.log('numero aleatorio: ', randomNumberA);
    this.randomNumber = randomNumberA;
    return randomNumberA;
  }

//---Carga de iamgenes

 openD(index: number): void {
    this.lightbox.open(this.imagen, index);
  }

  closeD(): void {
    this.lightbox.close();
  }

 open(index: number): void {
    // open lightbox
    this._lightbox.open(this.albums, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  openLightbox(content: any) {
    this.modalService.open(content, { centered: true });
  }

  selectImage(index: number): void {
    this.selectedImage = this.albums[index];
  }

  hoverImage(isHovering: boolean) {
    this.isHovered = isHovering;
  }


//---cambia la estructura de las imagenes

  initializeAlbums(): void {
    this.albums = this.images.map((image, index) => ({
      src: image,
      caption: `${index + 1}/${this.images.length}`,
      thumb: image,
      type: null || 'image' // Default to 'image' if type is not provided
    }));
    this.selectImage(0); // Select the first image initially
  }

  goToCar(){
     this.router.navigate(['/cart']);
  }


  regresar(): void {
    this.router.navigate(['/product']);
   }

}
