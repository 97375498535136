import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-order-total',
  templateUrl: './order-total.component.html',
  styleUrls: ['./order-total.component.scss']
})
export class OrderTotalComponent implements OnInit {

  @Input() total: number;
  @Input() envio: any;
  @Input() granTotal: any;
  @Input() productCount: any;

  constructor(private router: Router, private route: ActivatedRoute) {
    this.total = 0;
    this.envio = 0;
    this.granTotal = 0;
    this.productCount = 0;
  }

  ngOnInit(): void {
    // En OrderTotalComponent
    localStorage.setItem('granTotal', JSON.stringify(this.granTotal));
  }

  regresar(): void {
  this.router.navigate(['/product']);
 }

}

