import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Product} from "../../models/product";
import {Router} from "@angular/router";

@Component({
  selector: 'app-grid-view',
  templateUrl: './grid-view.component.html',
  styleUrls: ['./grid-view.component.scss']
})
export class GridViewComponent implements OnInit {

  public p: number = 1;
  @Input() productList: any;
  @Input() searchKey: any;
  @Output() addItems: EventEmitter<any> = new EventEmitter();
  @Output() viewProduct: EventEmitter<Product> = new EventEmitter();

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  addToCart(item: string) {
    console.log('Navegación completada: ', item);
    this.addItems.emit(item);
  }

  viewProductDetail(product: any) {
    this.router.navigate(['/product', product.id], {
      state: { product: product }
    }).then(() => {
      // Puedes realizar alguna acción aquí si es necesario
      console.log('Navegación completada');
    });

  }

// Método para convertir HTML a texto plano, eliminar saltos de línea y truncarlo.
truncateHtml(text: string, maxLength: number = 100): string {
  // Crear un elemento temporal para convertir el HTML en texto plano.
  const div = document.createElement('div');
  div.innerHTML = text;
  
  // Obtener el texto sin etiquetas HTML y eliminar saltos de línea.
  let plainText = (div.textContent || div.innerText || '').replace(/\s+/g, ' ');

  // Truncar el texto a la longitud máxima especificada (100 caracteres).
  return plainText.length > maxLength 
    ? plainText.substring(0, maxLength) + '...' 
    : plainText;
}

// Método para obtener una versión truncada de la descripción del producto.
getTruncatedDescription(description: string): string {
  return this.truncateHtml(description, 100); // Usa 100 como límite
}



}
