
<div *ngIf="!token" class="container row my-5">
  <h1>¡Regístrate o inicia sesión para continuar!</h1>
  <br>
  <h2>Login o registro</h2>
  <form [formGroup]="contactForm">
    <p>
      <mat-form-field
        appearance="outline"
        class="controles-container botonfull"
      >
        <mat-label>Usuario</mat-label>
        <input
          matInput
          type="text"
          placeholder="Ingresa tu usuario"
          formControlName="email"
        />
      </mat-form-field>
    </p>
    <p>
      <mat-form-field
        appearance="outline"
        class="controles-container botonfull"
      >
        <mat-label>Contraseña</mat-label>
        <input
          matInput
          [type]="hide ? 'password' : 'text'"
          placeholder="Ingresa tu contraseña"
          formControlName="password"
        />
        <span
          mat-icon-button
          matSuffix
          (click)="hide = !hide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
        >
            <mat-icon color="accent">{{
              hide ? "visibility_off" : "visibility"
            }}</mat-icon>
          </span>
      </mat-form-field>
    </p>
    <mat-error *ngIf="error">
      {{ error }}
    </mat-error>
    <p></p>
    <div class="row justify-content-between">
      <div class="col-auto">
        <button
          type="submit"
          mat-flat-button
          color="primary"
          [disabled]="!contactForm.valid"
          class="controles-container"
          (click)="onLogin()"
        >
          Login
        </button>
      </div>
      <div class="col">
        <button
          type="submit"
          mat-flat-button
          color="primary"
          [disabled]="!contactForm.valid"
          class="controles-container"
          (click)="register()"
        >
          Register
        </button>
      </div>
    </div>
  </form>
</div>

<div class="order-list-container">
  <h2 class="order-list-title">Mis Pedidos</h2>
  <div *ngFor="let order of orders" class="order-card mat-elevation-z4">
    <div class="order-header">
      <h3 class="order-title">Pedido ID: <span>{{ order.id }}</span></h3>


      <p class="order-user">Usuario: <span>{{ order.user }}</span></p>
      <p class="order-email">Email: <span>{{ order.email }}</span></p>
    </div>
    <div class="order-body">
      <p class="order-name">Nombre: <span>{{ order.name }}</span></p>
      <p class="order-status">Estatus: <span>{{ order.status }}</span></p>
      <p class="order-shipping">Método de Envío: <span>{{ order.shippingMethod }}</span></p>
      <p class="order-total">Total Pagado: <span class="order-total-amount">${{ order.totalPayment | number:'1.2-2' }}</span></p>
      <p class="order-create-date">Fecha de Creación: <span>{{ order.createAt ? translateDate(order.createAt) : 'N/A'  }}</span></p>
      <!--p class="order-delivery-date">Fecha de Entrega: <span>{{ order.deliveryDate | date:'d MMMM yyyy' }}</span></p-->


      <p>Fecha estimada de entrega:
        <strong>
          entre {{ order.createAtPlus5BusinessDays ? translateDate(order.createAtPlus5BusinessDays) : 'N/A' }} y
          {{ order.createAtPlus20BusinessDays ? translateDate(order.createAtPlus20BusinessDays) : 'N/A' }}
        </strong>
      </p>




    </div>
    <div class="order-actions">
      <button mat-raised-button color="primary" (click)="viewOrderDetails(order)">Ver Detalles</button>
    </div>
  </div>
</div>
