<div class="wrapper">
  <div class="body">
    <h1>¡Bienvenido!</h1>

    <img class="logo" src="assets/camben-logo-oficial.png" alt="logo" />
    <br />
    <br />
    <div *ngIf="!showForgotPassword">

      <form [formGroup]="contactForm">
        <p>
          <mat-form-field
            appearance="outline"
            class="controles-container botonfull"
          >
            <mat-label>Usuario</mat-label>
            <input
              matInput
              type="text"
              placeholder="Ingresa tu usuario"
              formControlName="email"
            />
          </mat-form-field>
        </p>
        <p>
          <mat-form-field
            appearance="outline"
            class="controles-container botonfull"
          >
            <mat-label>Contraseña</mat-label>
            <input
              matInput
              [type]="hide ? 'password' : 'text'"
              placeholder="Ingresa tu contraseña"
              formControlName="password"
            />
            <span
              mat-icon-button
              matSuffix
              (click)="hide = !hide"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide"
            >
            <mat-icon color="accent">{{
              hide ? "visibility_off" : "visibility"
            }}</mat-icon>
          </span>
          </mat-form-field>
        </p>
        <mat-error *ngIf="error">
          {{ error }}
        </mat-error>
        <p></p>
        <div class="row justify-content-between">
          <div class="col">
            <button
              type="submit"
              mat-flat-button
              color="primary"
              [disabled]="!contactForm.valid"
              class="controles-container"
              (click)="onLogin()"
            >
              Login
            </button>
          </div>
          <div class="col">
            <button
              type="submit"
              mat-flat-button
              color="primary"
              [disabled]="!contactForm.valid"
              class="controles-container"
              (click)="register()"
            >
              Register
            </button>
          </div>
        </div>
        <!--div class="measurement-table">
                <span class="toggle-text" (click)="resetPassword()">
                  <u style="color: blue;">¿Olvidaste tu contraseña?</u>
                  <i class="fas fa-chevron-down"></i>
                </span>
        </div-->


        <div class="measurement-table">
        <span class="toggle-text" (click)="toggleForgotPassword()">
          <u class="hover-effect">¿Olvidaste tu contraseña?</u>
          <i class="fas fa-chevron-down"></i>
        </span>
        </div>

      </form>
    </div>


    <div *ngIf="showForgotPassword" class="forgot-password-container">
      <h2>Restablecer tu contraseña</h2>
      <form (ngSubmit)="resetPassword()">
        <div class="form-group">
          <label for="email">Ingresa tu correo electrónico</label>
          <input
            type="email"
            id="email"
            [(ngModel)]="email"
            name="email"
            class="form-control"
            required
          />
        </div>
        <br>
        <div class="d-flex align-items-center">
          <!--button type="button" class="btn btn-danger" (click)="toggleForgotPassword()"-->
            <mat-icon (click)="toggleForgotPassword()" class="me-2 icon-hover" style="cursor: pointer;">arrow_back</mat-icon>
          <!--/button-->

          <button type="submit" class="btn btn-primary" [disabled]="loading">
            <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
            <span *ngIf="!loading">{{ loading ? '' : 'Enviar enlace al correo' }}</span>
          </button>
        </div>

      </form>
      <div *ngIf="message" class="alert alert-info mt-3">{{ message }}</div>
    </div>

  </div>
</div>



<!--div class="container-login">
    <div class="login d-flex justify-content-center">
      <div class="login_form">
        <div class="centrar">
          <form [formGroup]="contactForm" (ngSubmit)="onLogin()">
            <img class='logo' src="assets/camben-logo-oficial.png" alt="logo"/>
            <br>
            <br>
            <p class="bienvenida">
              ¡Te damos la bienvenida!
            </p>
            <p>
              <mat-form-field appearance="outline" class="controles-container">
                <mat-label>Usuario</mat-label>
                <input [(ngModel)]="nombre" matInput type="text" placeholder="Ingresa tu usuario" formControlName="email">
              </mat-form-field>
            </p>
            <p>
              <mat-form-field appearance="outline" class="controles-container">
                <mat-label>Contraseña</mat-label>
                <input matInput [(ngModel)]="contrasena" [type]="hide ? 'password' : 'text'" placeholder="Ingresa tu contraseña"
                       formControlName="password">
                <span mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                      [attr.aria-pressed]="hide">
                  <mat-icon color="accent">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </span>
              </mat-form-field>
            </p>
            <mat-error *ngIf="error">
              {{ error }}
            </mat-error>
            <p>
            </p>
            <div class="button">
              <button type="submit"  mat-flat-button color='primary'
                      [disabled]="!contactForm.valid"
                      class="controles-container">Entrar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div-->
