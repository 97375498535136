import {HttpClient, HttpHeaders } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {environment} from 'src/environments/environment';
import {Product} from '../shared/models/product';
import { Order } from '../shared/models/order';
import { Dates } from '../shared/models/order';
import {API_ENDPOINT} from '../shared/Constant/constant';
import {HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public baseUrl = environment.apiUrl;
  public cartItem: any = [];
  //public $product = new Subject();
  public search = new BehaviorSubject<string>("");
  public $product = new BehaviorSubject<any[]>(this.getCart());

  private cartKey = 'cartItems';

  //Guarda la orden
  private orderSource = new BehaviorSubject<Order | null>(null);
  currentOrder = this.orderSource.asObservable();

  constructor(private http: HttpClient) {
     this.cartItem = this.getCart();
  }


//consulta la orden
 setOrder(order: Order): void {
    this.orderSource.next(order);
  }

  //consulta las ordenes
  searchOrders(user: string, email: string): Observable<Order[]> {
    const url = `${this.baseUrl}${API_ENDPOINT.ORDER_SEARCH}?user=${user}&email=${encodeURIComponent(email)}`;
    return this.http.get<Order[]>(url);
  }

  createOrder(order: Order): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(`${this.baseUrl}${API_ENDPOINT.ORDER}`, order, { headers });
  }

  getMaxDatesOrder(): Observable<any> {
    return this.http.get<Dates>(`${this.baseUrl}${API_ENDPOINT.DATES}`)
  }

  uploadImage(file: File, origin: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('origin',origin)

    return this.http.post(`${this.baseUrl}${API_ENDPOINT.UPLOADURL}`, formData);
  }

  getAllProduct(offset: number, limit: number): Observable<any> {
    const params = new HttpParams()
      .set('offset', offset.toString())
      .set('limit', limit.toString());
    return this.http.get<Product>(`${this.baseUrl}${API_ENDPOINT.PRODUCTS}`, {params})
  }

  getAllProductList(): Observable<any> {
    return this.http.get<Product>(`${this.baseUrl}${API_ENDPOINT.PRODUCTS_LIST}`)
  }

  getAllCategory(): Observable<any> {
    return this.http.get(`${this.baseUrl}${API_ENDPOINT.PRODUCTS_CATEGORIES}`);
  }

  getPriceRange(minPrice: number, maxPrice: number): Observable<any> {
    const params = new HttpParams()
      .set('minPrice', minPrice)
      .set('maxPrice', maxPrice);
    return this.http.get(`${this.baseUrl}${API_ENDPOINT.PRODUCTS_BY_PRICE_RANGE}`, {params});
  }

  getInCategory(type: string): Observable<any> {
    return this.http.get(`${this.baseUrl}${API_ENDPOINT.PRODUCTS_CATEGORIES_TYPE}${type}`);
  }

  getSingleProduct(id: number) {
    return this.http.get(`${this.baseUrl}${API_ENDPOINT.PRODUCTS}${id}`);
  }
/*
  addProductToCart(item: Product): void {
    this.cartItem.push(item);
    this.$product.next(this.cartItem);
    localStorage.setItem('cart', JSON.stringify(this.cartItem));
  }*/

/*
  getProductsCount(): Observable<any> {
    return this.$product.asObservable();
  }*/

  getCart(): Product[] {
    return JSON.parse(localStorage.getItem('cartItems') || '[]');

  }

  getTotalPrice(): number {
    return this.cartItem.reduce((total: number, item: { price: number; }) => total + item.price, 0);
  }

  /*removeItemFromCheckOut(productId: string): void {
    this.cartItem.map((item: Product, index: number) => {
      if (item.idTable === productId) {
        this.cartItem.splice(index, 1);
      }
    });
    localStorage.setItem('cart', JSON.stringify(this.cartItem));
    return this.$product.next(this.cartItem);
  }*/
/*
  getCart(): any[] {
    const cart = localStorage.getItem(this.cartKey);
    return cart ? JSON.parse(cart) : [];
  }*/


  updateCartItem(updatedItem: any): void {
    let cart = this.getCart();
    const index = cart.findIndex((item: any) => item.idTable === updatedItem.idTable);
    if (index !== -1) {
      cart[index] = updatedItem;
      localStorage.setItem(this.cartKey, JSON.stringify(cart)); // Actualiza el localStorage
    }
  }


  removeItemFromCheckOut(productId: string): void {
    let cart = this.getCart();
    cart = cart.filter(item => item.idTable !== productId); // Filtra el producto a eliminar
    localStorage.setItem(this.cartKey, JSON.stringify(cart)); // Actualiza el localStorage
    this.$product.next(cart); // Notifica a los suscriptores sobre el cambio
  }


  addProductToCart(item: Product): void {
    this.cartItem.push(item);
    localStorage.setItem(this.cartKey, JSON.stringify(this.cartItem)); // Sincroniza con localStorage
    this.$product.next(this.cartItem); // Notifica a los suscriptores
  }

  getProductsCount(): Observable<any[]> {
    return this.$product.asObservable();
  }
// Método para vaciar el carrito
  clearCart(): void {
    this.cartItem = [];
    localStorage.removeItem('cartItems');
  }
}
