<div *ngIf="order" class="order-details-container">
  <h2>Detalles del Pedido</h2>
  <div class="order-header" *ngIf="order">


    <!-- Barra de progreso -->
    <!--div class="progress-bar-container" style="background-color: #eee; border-radius: 5px; height: 20px;">
      <div
        class="progress-bar"
        [style.width.%]="calculateProgress(order?.status)"
        [ngClass]="{ completed: order?.status === 'ENTREGADO' }"
      ></div>
    </div>

    <p class="current-status">
      {{ getStatusText(order?.status) }}
    </p-->






    <!--div class="order-progress">
      <h2>Estado de tu pedido</h2>
      <div class="progress-container">
        <div
          class="progress-bar"
          [style.width]="calculateProgress(order?.status) + '%'"
        ></div>
      </div>
      <div class="status-labels">
        <div *ngFor="let status of statusOrder">
      <span
        [ngClass]="{
          'active': order?.status && isActive(status),
          'completed': order?.status && isCompleted(status)
        }">
        {{ status }}
      </span>
        </div>
      </div>
      <button (click)="regresar()">Regresar a Pedidos</button>
    </div-->




    <div class="order-progress">
      <h2>Estado de tu pedido</h2>
      <div class="progress-container">
        <div
          class="progress-bar"
          [style.width]="calculateProgress(order?.status) + '%'"
        ></div>
      </div>
      <div class="status-labels">
        <div *ngFor="let status of visibleStatusOrder">
      <span
        [ngClass]="{
          'active': order?.status && isActive(status),
          'completed': order?.status && isCompleted(status)
        }">
        {{ status }}
      </span>
        </div>
      </div>
      <button (click)="regresar()">Regresar a Pedidos</button>
    </div>




    <div class="payment-summary-card">
      <h3>Datos del pedido</h3>
      <h3>Pedido ID: <span>{{ order?.id }}</span></h3>
      <p>Estatus: <span>{{ order?.status }}</span></p>
      <p>Fecha estimada de entrega:
        <strong>
          entre {{ order.createAtPlus5BusinessDays ? translateDate(order.createAtPlus5BusinessDays) : 'N/A' }} y
          {{ order.createAtPlus20BusinessDays ? translateDate(order.createAtPlus20BusinessDays) : 'N/A' }}
        </strong>
      </p>
      <!--p>Observaciones: <span>{{ order?.observations }}</span></p-->
    </div>


    <div class="payment-summary-card">
      <h3>Datos del cliente</h3>
      <!--p>Usuario: <span>{{ order?.user }}</span></p-->
      <p>Nombre: <span>{{ order?.name }}</span></p>
      <p>Email: <span>{{ order?.email }}</span></p>
      <p>Teléfono: <span>{{ order?.phone }}</span></p>
      <p>Dirección: <span>{{ order?.street }} {{ order?.numberHome }}, {{ order?.colony }}, {{ order?.city }}, {{ order?.state }}, {{ order?.zipCode }}, {{ order?.country }}</span></p>
      <p>Fecha de Creación: <span>{{ order?.createAt | date:'d MMMM yyyy' }}</span></p>
      <!--p>Fecha de Entrega: <span>{{ order?.deliveryDate | date:'d MMMM yyyy' }}</span></p-->
    </div>







    <!--p>Total Pagado: <span>${{ order?.totalPayment | number:'1.2-2' }}</span></p>
    <p>Subtotal: <span>${{ order?.subtotal | number:'1.2-2' }}</span></p-->
    <!--p>Descuento: <span>${{ order?.discount | number:'1.2-2' }}</span></p-->
    <!--p>Impuesto: <span>${{ order?.tax | number:'1.2-2' }}</span></p-->
  </div>

  <div class="payment-summary-card">
    <h3>Resumen de Envio</h3>
    <p>Método de Envío: <span>{{ order?.shippingMethod }}</span></p>
    <p>Tipo de Envío: <span>{{ order?.shippingType }}</span></p>
    <p>Costo de Envío: <span>${{ order?.shippingCost | number:'1.2-2' }}</span></p>

    <p class="shipping-info">
      Nombre de la paqueteria: <span class="highlight">{{ order?.parcelName }}</span>
    </p>
    <p class="shipping-info">
      Número de Guía: <span class="highlight">{{ order?.guideNumber }}</span>
    </p>
  </div>

  <div class="payment-summary-card">
    <h3>Resumen de Pago</h3>
    <p>Total Pagado: <span class="amount">${{ order?.totalPayment | number:'1.2-2' }}</span></p>
    <p>Subtotal: <span class="amount">${{ order?.subtotal | number:'1.2-2' }}</span></p>
    <p>Costo de envio: <span class="amount">${{ order?.shippingCost | number:'1.2-2' }}</span></p>
  </div>


  <div class="order-products">
    <h3>Productos</h3>
    <div *ngFor="let product of order.products" class="product-card">
      <img [src]="product.coverImage" alt="{{ product.product }}">
      <h4>{{ product.product }} - {{ product.model }}</h4>
      <p>Marca: <span>{{ product.brand }}</span></p>

      <p>Categoría: <span>{{ product.category }}</span></p>
      <p>Subcategoría: <span>{{ product.subcategory }}</span></p>

      <p>Color seleccionado:
        <span>
          {{ product.selectedColor ? product.selectedColor.name : 'Sin color' }}
        </span>
      </p>

      <p>Talla seleccionada:
        <span>{{ product.selectedSize ? product.selectedSize.size : 'Sin talla' }}</span>
      </p>

      <div *ngIf="product.personalized">
        <p>Nombre personalizado:
          <span>{{ product.selectedSize ? product.selectedSize.namePerson : 'Sin personalizado' }}</span>
        </p>
        <p>Tipo de sangre:
          <span>{{ product.selectedSize ? product.selectedSize.bloodType : 'Sin tipo de sangre' }}</span>
        </p>
        <p>Cargo:
          <span>{{ product.selectedSize ? product.selectedSize.position : 'Sin puesto' }}</span>
        </p>
        <p>Nombre del club:
          <span>{{ product.selectedSize ? product.selectedSize.club : 'Sin nombre del club' }}</span>
        </p>
        <p>Años de servicio:
          <span>{{ product.selectedSize ? product.selectedSize.yearsService : 'Sin años de servicio' }}</span>
        </p>
      </div>

      <p>Precio: <span>${{ product.priceShop | number:'1.2-2' }}</span></p>
      <!--p>Precio con Promoción: <span>${{ product.promoPrice | number:'1.2-2' }}</span></p-->
      <p>Cantidad: <span>{{ product.quantity }}</span></p>

      <!--p>Descripción: <span>{{ product.description }}</span></p-->
      <p><strong>Descripción:</strong> <span [innerHTML]="product.description"></span></p>

    </div>
  </div>

  <div class="order-payments">
    <h3>Pagos</h3>
    <div *ngFor="let payment of order.pay" class="payment-card">
      <p>Método de Pago: <span>{{ payment.methodPay }}</span></p>
      <p>Importe: <span>${{ payment.amount }}</span></p>
      <p>Estado: <span>{{ payment.status }}</span></p>
      <p>Concepto: <span>{{ payment.concepto }}</span></p>
      <p>Descripción: <span>{{ payment.description }}</span></p>
      <!--a [href]="payment.urlPay" target="_blank">Ver Pago</a-->
      <div *ngIf="payment.urlPay" class="payment-image-container">
        <img [src]="payment.urlPay" alt="Image" />
      </div>
      <!--p>Comentario: <span>{{ payment.comentary }}</span></p-->
    </div>
  </div>

  <!--div class="order-origin">
    <h3>Origen</h3>
    <p><span>{{ order.origin }}</span></p>
  </div-->

  <div>

    <button type="button"class="btn btn-danger" (click)="regresar()" style="background-color: #ff4d4d; color: white; border: none; cursor: pointer; transition: background-color 0.3s ease;">
      Regresar
    </button>
  </div>
</div>
