import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Product} from "../../models/product";
import {Router} from "@angular/router";

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss']
})
export class ListViewComponent implements OnInit {

  public p: number = 1;
  @Input() productList: any;
  @Input() searchKey = '';
  @Output() addItems: EventEmitter<any> = new EventEmitter();
  @Output() viewProduct: EventEmitter<Product> = new EventEmitter();

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  addToCart(item: string) {
    this.addItems.emit(item);
  }

  viewProductDetail(product: any) {
    
    const truncatedDescription = this.getTruncatedDescription(product.description);
    
    this.router.navigate(['/product', product.id], {
      state: { product: { ...product, description: truncatedDescription } }
    }).then(() => {
      // Puedes realizar alguna acción aquí si es necesario
      console.log('Navegación completada');
    });

  }

  // Método para convertir HTML a texto plano, eliminar saltos de línea y truncarlo.
  truncateHtml(text: string, maxLength: number = 100): string {
    const div = document.createElement('div');
    div.innerHTML = text;
    
    // Obtener el texto sin etiquetas HTML y eliminar saltos de línea.
    let plainText = (div.textContent || div.innerText || '').replace(/\s+/g, ' ');

    // Truncar el texto a la longitud máxima especificada (100 caracteres).
    return plainText.length > maxLength 
      ? plainText.substring(0, maxLength) + '...' 
      : plainText;
  }

  // Método para obtener una versión truncada de la descripción del producto.
  getTruncatedDescription(description: string): string {
    return this.truncateHtml(description, 250); // Usa 100 como límite
  }

}
