import {Component, OnInit} from '@angular/core';
import {Product} from '../../shared/models/product';
import {Dates} from '../../shared/models/order';
import {DataService} from '../../service/data.service';
import {SORT} from '../../shared/Constant/constant';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  maxCreateAt: string | undefined;
  createAtPlus5BusinessDays: string | undefined;
  createAtPlus20BusinessDays: string | undefined;


  public productList: any = [];
  public isLoading: boolean = false;
  public rangeResult: number = 0;
  public filterData: any;
  totalProducts: number = 0;
  currentPage: number = 1;
  itemsPerPage: number = 10;
  public istoggelGridListView = 'grid';
  public searchKey!: string;

  //generar numero randon para id de idTabla
    randomNumber: any;

  constructor(private data: DataService) {
  }

  ngOnInit(): void {

    const storedMaxCreateAt = sessionStorage.getItem('maxCreateAt');
    const storedCreateAtPlus5 = sessionStorage.getItem('createAtPlus5BusinessDays');
    const storedCreateAtPlus20 = sessionStorage.getItem('createAtPlus20BusinessDays');

    if (storedMaxCreateAt && storedCreateAtPlus5 && storedCreateAtPlus20) {
      this.maxCreateAt = storedMaxCreateAt;
      this.createAtPlus5BusinessDays = storedCreateAtPlus5;
      this.createAtPlus20BusinessDays = storedCreateAtPlus20;
    } else {
      this.fetchOrderDates();
    }


    this.getAllProduct();
    this.getSearchedValue();
  }

  /*getAllProduct(page: number = 1): void {
    this.isLoading = true;
    const offset = (page - 1) * this.itemsPerPage;
    this.data.getAllProduct(offset, this.itemsPerPage).subscribe((res: Product) => {
      this.productList = res;
      this.currentPage = page;
      this.isLoading = false;
      this.filterData = this.productList;
      this.getMaxPrice();
    });
  }*/

  getAllProduct(page: number = 1): void {
    this.isLoading = true;
  
    // Llamamos al servicio que obtiene todos los productos (sin paginación)
    this.data.getAllProductList().subscribe((res: Product[]) => {
      this.productList = res;      // Asignamos todos los productos a la lista
      this.filterData = this.productList;  // Si tienes un filtro aplicado, lo asignamos aquí
      this.isLoading = false;  // Termina el estado de carga
      this.getMaxPrice();      // Llamamos a la función que posiblemente calcule el precio máximo o realice alguna acción adicional
    });
  }
  


fetchOrderDates(): void {
  this.data.getMaxDatesOrder().subscribe(
    (data) => {
      this.maxCreateAt = data.maxCreateAt;
      this.createAtPlus5BusinessDays = data.createAtPlus5BusinessDays;
      this.createAtPlus20BusinessDays = data.createAtPlus20BusinessDays;

      // Verifica que las variables no sean undefined antes de guardar en sessionStorage
      if (this.maxCreateAt) {
        sessionStorage.setItem('maxCreateAt', this.maxCreateAt);
      }

      if (this.createAtPlus5BusinessDays) {
        sessionStorage.setItem('createAtPlus5BusinessDays', this.createAtPlus5BusinessDays);
      }

      if (this.createAtPlus20BusinessDays) {
        sessionStorage.setItem('createAtPlus20BusinessDays', this.createAtPlus20BusinessDays);
      }
    },
    (error) => {
      console.error('Error:', error);
    }
  );
}


  getSearchedValue() {
    this.data.search.subscribe((val: any) => {
      this.searchKey = val;
    })
  }

  getMaxPrice(): number {
    return this.rangeResult = Math.max(...this.productList.map((o: Product) => o.priceShop));
  }

  addToCart(productId: String): void {

    this.generateRandomNumber();

    let addToCart = this.productList.filter((item: Product) => {
      return item.id === productId;
    });
    this.data.addProductToCart(addToCart[0]);
  }


//genera numero aleatorio para id de tabla
  generateRandomNumber(): number {
    // Genera un número aleatorio de 6 dígitos
    const randomNumberA = Math.floor(100000 + Math.random() * 900000);
    console.log('numero aleatorio: ', randomNumberA);
    this.randomNumber = randomNumberA;
    return randomNumberA;
  }

  sortProduct(event: any): Product [] {
    switch (event.target.value) {
      case SORT.Low_price: {
        this.sortByLowToHigh();
        break;
      }
      case SORT.High_price: {
        this.sortByHighToLow();
        break;
      }
      // case SORT.Low_rating: {
      //   this.sortByLowhRating();
      //   break;
      // }
      // case SORT.High_rating: {
      //   this.sortByHighRating();
      //   break;
      // }
      case SORT.Name: {
        this.sortByName();
        break;
      }

      default: {
        this.sortByLowToHigh();
        break;
      }

    }
    return this.productList
  }

  sortByLowToHigh(): Product [] {
    return this.productList = this.productList.sort((low: any, high: any) => {
      return low.priceShop - high.priceShop;
    });
  }

  sortByHighToLow(): Product [] {
    return this.productList = this.productList.sort((low: any, high: any) => {
      return high.priceShop - low.priceShop;
    });
  }

  sortByName(): Product [] {
    return this.productList.sort((low: any, high: any) =>
      low.product.localeCompare(high.product)
    );
  }

  rangeChange(event: Event): void {
    this.productList = this.filterData.filter((item: any) => {
      return item.priceShop >= event;
    });
  }

  getCategory(event: Event): void {
    this.productList = event;
  }

  getMinMaxPrice(event: Event): void {
    this.productList = event;
  }

  toggelGridListView(mode: string) {
    this.istoggelGridListView = mode;
  }
}
