<p-accordion>
  <p-accordionTab header="Filter By Categories" [selected]="true">
    <form class="mb-3">
      <div class="input-group">
        <input type="text"
               class="form-control"
               placeholder="Filter by Product Name"
               [(ngModel)]="searchTerm"
               (keyup)="search($event)"
               [ngModelOptions]="{standalone: true}">
        <!--         <div class="input-group-append">-->
        <!--          <button class="btn btn-bg" type="button"><i class="bi bi-search"></i></button>-->
        <!--        </div>-->
      </div>
    </form>
    <ul class="list-group " *ngFor="let cat of catagoryList;let i=index">
      <li class="list-group-item border-0 d-flex justify-content-between align-items-center"
          (click)="isSelected(cat)">
        {{ cat.category }}
        <span class="badge btn-bg rounded-pill">{{ cat.count }}</span>
      </li>
    </ul>
  </p-accordionTab>
  <p-accordionTab header="Filter By Price" [selected]="true">
    <div class="range-container">
      <div class="range-values">
        <span>{{ inputMin | currency }}</span>
        <span>{{ priceRangeChange | currency }}</span>
        <span>{{ inputMax | currency }}</span>
      </div>
      <input type="range"
             class="form-range"
             [(ngModel)]="priceRangeChange"
             [value]="priceRangeChange"
             [min]="inputMin"
             [max]="inputMax"
             (change)="onRangeChange($event)">
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <label>Min</label>
        <input type="number"
               class="form-control"
               min="0"
               placeholder="$0"
               name="minPrice"
               [ngModel]="minPrice"
               (ngModelChange)="onInputChangeMinPriceRange($event)">
      </div>
      <div class="form-group text-right col-md-6">
        <label>Max</label>
        <input type="number"
               class="form-control"
               min="0"
               name="maxPrice"
               [ngModel]="maxPrice"
               (ngModelChange)="onInputChangeMaxPriceRange($event)"
               placeholder="$1,000">
      </div>
    </div>

    <div class="col-12 mt-2">
      <button class="btn btn-bg d-block w-100" (click)="filterPriceRange()">Apply</button>
    </div>
  </p-accordionTab>
  <!--<p-accordionTab header="Filter By Size" [selected]="true">
    <label class="checkbox-btn">
      <input type="checkbox">
      <span class="btn btn-light"> XS </span>
    </label>

    <label class="checkbox-btn">
      <input type="checkbox">
      <span class="btn btn-light"> SM </span>
    </label>

    <label class="checkbox-btn">
      <input type="checkbox">
      <span class="btn btn-light"> LG </span>
    </label>

    <label class="checkbox-btn">
      <input type="checkbox">
      <span class="btn btn-light"> XXL </span>
    </label>
  </p-accordionTab>-->
</p-accordion>
