<div class="row mt-5">
  <aside class="col-md-3">
    <app-cat-sidebar (rangeChange)="rangeChange($event)"
                     (GetCategory)="getCategory($event)"
                     (MinMaxPrice)="getMinMaxPrice($event)"
                     [inputMax]="rangeResult"
                     [priceRangeChange]="10">
    </app-cat-sidebar>
  </aside>
  <div class="col-md-9">
    <div class="row border-bottom pb-4 mb-5">

      <div class="col-md-7">
        <span *ngIf="productList && productList.length>0">{{ productList.length }} Items found </span>
        <span *ngIf="productList && productList.length === 0">There are <strong>no results.</strong></span>
      </div>
      <div class="col-md-5">
        <div class="row g-3">
          <div class="col">
            <select class="form-select" aria-label="Latest items" (change)="sortProduct($event)">
              <option value="Name" selected>Default Sorting</option>
              <!--              <option value="Low-rating">Show low to high rating</option>-->
              <!--              <option value="High-rating">Show high to low rating</option>-->
              <option value="Low-price">Show low to high price</option>
              <option value="High-price">Show high to low price</option>
            </select>
          </div>
          <div class="col-3 d-flex justify-content-end">
            <div class="btn-group" role="group" aria-label="grid layout">
              <button class="btn btn-outline-secondary " pTooltip="List view" tooltipPosition="bottom"
                      (click)="toggelGridListView('list')" [ngClass]="{'active': istoggelGridListView === 'list'}">
                <i class="bi bi-list-task"></i></button>
              <button class="btn  btn-outline-secondary" pTooltip="Grid view" tooltipPosition="bottom"
                      (click)="toggelGridListView('grid')" [ngClass]="{'active': istoggelGridListView === 'grid'}">
                <i class="bi bi-grid-3x3"></i></button>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="d-flex justify-content-center" *ngIf="isLoading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <ng-container *ngIf="istoggelGridListView === 'grid'">
      <app-grid-view [productList]="productList" [searchKey]="searchKey" (addItems)="addToCart($event)"></app-grid-view>
    </ng-container>


    <ng-container *ngIf="istoggelGridListView === 'list'">
      <app-list-view [productList]="productList" [searchKey]="searchKey" (addItems)="addToCart($event)"></app-list-view>
    </ng-container>

  </div>
</div>
