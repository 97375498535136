import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Order } from '../shared/models/order';
import { DataService } from '../service/data.service';

@Component({
  selector: 'app-order-details',
  //standalone: true,
  //imports: [],
  templateUrl: './order-details.component.html',
  styleUrl: './order-details.component.scss'
})
export class OrderDetailsComponent {
  order: Order | undefined;

  // Define el orden de los estatus
private statusOrder = [
  'Pedido',
  'Orden confirmada',
  'Pago pendiente',
  'Pago confirmado',
  'Pago rechazado',
  'Orden en preparación',
  'Corte',
  'Confección',
  'Control de calidad',
  'Listo para enviar',
  'Enviado',
  'Entregado'
];

// Define los nombres de los estados que se mostrarán en la barra
public visibleStatusOrder = [
  'Orden confirmada',
  'Pago confirmado',
  'Orden en preparación',
  'Enviado',
  'Entregado'
];


  selectedColorName: string = 'Sin color';
  selectedColorHex: string = 'black';
  selectedSize: string = 'Sin talla';


    constructor(private router: Router, private route: ActivatedRoute,  private data: DataService) { }

  /*ngOnInit(): void {
    const navigation = this.router.getCurrentNavigation();
    console.error('navigation del detalle: ', navigation);

    if (navigation && navigation.extras && navigation.extras.state) {
      this.order = navigation.extras.state['order'] as Order;
      console.error('Order del detalle: ', this.order);
    } else {
      console.error('No order data available');
    }
  }*/

ngOnInit(): void {
    this.data.currentOrder.subscribe(order => {
      console.log('Orden recibida:', order); // Verifica que recibas la orden
      if (order) {
        this.order = order;
        console.error('Order del detalle: ', this.order);
        this.setProductDetails( this.order);
      } else {
        console.error('No order data available');
      }
    });


  }


  setProductDetails(order: Order) {
    if (order && order.products && order.products.length > 0) {
      const product = order.products[0]; // Asume que deseas obtener el primer producto

      // Asigna las propiedades de selectedColor directamente (asegurando que sea un objeto)
      this.selectedColorName = product.selectedColor?.name || 'Sin color';
      this.selectedColorHex = product.selectedColor?.hex || 'black';

      // Asigna las propiedades de selectedSize directamente (asegurando que sea un objeto)
      this.selectedSize = product.selectedSize?.size || 'Sin talla';

      // Imprimir para verificar que se estén asignando correctamente
      console.log('Selected Color Name:', this.selectedColorName);
      console.log('Selected Color Hex:', this.selectedColorHex);
      console.log('Selected Size:', this.selectedSize);
    } else {
      console.error('El producto no contiene la estructura esperada o no tiene productos');
    }
  }








    //cambia de idioma los meses
    translateDate(dateString: string | null | undefined): string {
      if (!dateString) return '';  // Manejar caso de null o undefined

      const date = new Date(dateString);

      return new Intl.DateTimeFormat('es-ES', { day: '2-digit', month: 'long', year: 'numeric' }).format(date);
    }

  regresar(): void {
    this.router.navigate(['/pedidos']);
   }
/*
 calculateProgress(status: string | undefined): number {
    console.log('Estado actual:', status); // Verifica el estado
    if (!status) return 0; // Si no hay estado, devuelve 0

    // Busca el índice del estado actual en el orden de estatus
    const index = this.statusOrder.indexOf(status);
    return index >= 0 ? ((index + 1) / this.statusOrder.length) * 100 : 0; // Calcula el porcentaje si el estado es válido
  }

  getStatusText(status: string | undefined): string {
    if (!status) return 'Estado desconocido'; // Manejar caso de undefined
    // Retorna el texto del estado directamente
    return status || 'Estado desconocido';
  }*/



calculateProgress(status: string | undefined): number {
    if (!status) return 0;
    const index = this.statusOrder.indexOf(status);
    return index >= 0 ? ((index + 1) / this.statusOrder.length) * 100 : 0;
  }

  isActive(status: string): boolean {
    const currentStatus = this.order?.status || '';
    return this.statusOrder.indexOf(status) === this.statusOrder.indexOf(currentStatus);
  }

  isCompleted(status: string): boolean {
    const currentStatus = this.order?.status || '';
    return this.statusOrder.indexOf(status) < this.statusOrder.indexOf(currentStatus);
  }
}
