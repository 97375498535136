import {Injectable} from '@angular/core';
import {Auth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut} from '@angular/fire/auth';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {API_ENDPOINT} from '../shared/Constant/constant';
import {HttpParams} from '@angular/common/http';
import {Login} from '../shared/models/login';
import {Token} from '../shared/models/token';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public baseUrl = environment.apiUrl;

  constructor(private auth: Auth, private http: HttpClient) {
  }

  /*register({email, password}: any) {
    return createUserWithEmailAndPassword(this.auth, email, password);
  }*/

 resetPassword(email: string): Observable<any> {
    const url = `${this.baseUrl}${API_ENDPOINT.RESET_PASSWORD}`;
    const body = { email: email };
    return this.http.post(url, body);
  }

  login(email: string, password: string): Promise<any> {
     const body = {
           email: email.toString(),
           password: password.toString()
         };
    return this.http.post<Login>(`${this.baseUrl}${API_ENDPOINT.LOGIN}`, body).toPromise();
    //return signInWithEmailAndPassword(this.auth, email, password);
  }

  validationLogin(token: string): Promise<any> {
     const body = {
           token: token.toString()
         };
    return this.http.post<Token>(`${this.baseUrl}${API_ENDPOINT.VALIDATION_LOGIN}`, body).toPromise();
    //return signInWithEmailAndPassword(this.auth, email, password);
  }

  register(email: string, password: string): Promise<any> {
     const body = {
           email: email.toString(),
           password: password.toString()
         };
    return this.http.post<Login>(`${this.baseUrl}${API_ENDPOINT.REGISTER}`, body).toPromise();
    //return signInWithEmailAndPassword(this.auth, email, password);
  }

  logout() {
    return signOut(this.auth);
  }
}
