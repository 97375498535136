<div class="bg-secondary p-4 shadow-sm">

  <p class="text-center text-success fw-bold">¡Envío gratis en compras a partir de $899!</p>
  <h3>Resumen de compra</h3>
  <hr/>
  <ul class="list-group ">
    <li class="list-group-item d-flex justify-content-between align-items-center border-0">
      <strong> Subtotal: ({{ productCount }} productos)</strong>
      <span class="badge bg-order-details rounded-pill">{{ total |currency }}</span>
    </li>
    <li class="list-group-item d-flex justify-content-between align-items-center border-0">
      Envío:
      <span  *ngIf="total == 0" class="badge bg-order-details  rounded-pill">{{ 0 |currency }}  </span>
      <span  *ngIf="total < 899 && total > 0 " class="badge bg-order-details  rounded-pill">{{ envio |currency }}  </span>
      <span *ngIf="total >= 899" class="badge bg-order-details  rounded-pill text-success"><strong>¡Envío gratis!</strong></span>
    </li>
    <!--li class="list-group-item d-flex justify-content-between align-items-center border-0">
      Descuento:
      <span class="badge bg-order-details rounded-pill">200</span>
    </li>
    <li class="list-group-item d-flex justify-content-between align-items-center border-0">
      Iva:
      <span class="badge bg-order-details rounded-pill">145</span>
    </li-->
    <li class="list-group-item d-flex justify-content-between align-items-center border-top border-0">
      Total de Orden:

      <span *ngIf="total == 0" class="badge bg-order-details rounded-pill">{{ 0 |currency }}</span>
      <span *ngIf="total != 0" class="badge bg-order-details rounded-pill">{{ granTotal |currency }}</span>
    </li>
  </ul>

</div>
<button type="button" class="btn btn-bg d-block w-100 mt-3" routerLink="/secure-checkout">
  Proceed to Checkout
</button>
<br>
<button type="button"class="btn btn-danger d-block w-100 mt-3" (click)="regresar()" style="background-color: #ff4d4d; color: white; border: none; cursor: pointer; transition: background-color 0.3s ease;">
  Seguir comprando
</button>



