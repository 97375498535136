export enum SORT {
  Low_price = "Low-price",
  High_price = "High-price",
  Low_rating = "Low-rating",
  High_rating = "High-rating",
  Name = "Name"
}

export enum API_ENDPOINT {
  PRODUCTS = "api/v1/products/paginado",
  PRODUCTS_LIST = "api/v1/products/list",
  PRODUCTS_CATEGORIES = "api/v1/categories",
  PRODUCTS_CATEGORIES_TYPE = "api/v1/products/category/",
  PRODUCTS_BY_PRICE_RANGE = "api/v1/products/priceRange",
  LOGIN = "api/v1/user/auth/login",
  VALIDATION_LOGIN = "api/v1/user/auth/verificacion",
  REGISTER = "api/v1/user/auth/register",
  UPLOADURL = "api/v1/storage/upload",
  ORDER = "api/v1/order",
  ORDER_SEARCH = "api/v1/order/search",
  RESET_PASSWORD = "api/v1/user/auth/resetPassword",
  DATES = "api/v1/order/dates"
}
