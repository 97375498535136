<div class="row my-5">
  <h2>Seleciona el metodo de pago</h2>
    <div class="row">
      <div class="col-md-8">
        <div class="row g-3 my-2">
          <!--div class="col">
            <select id="inputState"  placeholder="Favorite food" class="form-select" formControlName="paymentMethod" (change)="onPaymentMethodChange($event)">
              <option value="" disabled selected>Selecciona una forma de pago</option>
              <option value="transferencia">Transferencia</option>
            </select>
          </div-->

            <mat-form-field>
              <mat-select [(value)]="selected">
                <!--mat-option>Selecciona una forma de pago</mat-option-->
                <mat-option value="Selecciona una forma de pago" disabled >Selecciona una forma de pago</mat-option>
                <mat-option value="Transferencia">Transferencia</mat-option>
                <!--mat-option value="option3">Option 3</mat-option-->
              </mat-select>
            </mat-form-field>
        </div>

        <mat-card class="example-card " *ngIf="selected === 'Transferencia'">
          <br>
          <mat-card-header>
            <br>
            <div class="card-title">
              <mat-card-title>Forma de pago: {{selected}}</mat-card-title>
            </div>

            <div mat-card-avatar class="example-header-image"></div>
            <!--mat-card-subtitle>Titular de cuenta: <strong>camben elegance</strong></mat-card-subtitle>
            <br>
            <mat-card-subtitle>Clave interbancaria</mat-card-subtitle>
            <br>
            <mat-card-subtitle><strong>xxxx-xxxx-xxxx-xxxx</strong></mat-card-subtitle-->
            <br>
          </mat-card-header>
          <!--img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="Photo of a Shiba Inu"-->
          <mat-card-content>
            <div class="account-info">
              <p>Titular de cuenta: <strong>camben elegance</strong></p>
              <br>

              <p for="clipboard-example">Clave interbancaria</p>
              <div class="container">
                <div id="clipboard-example">
                  <p>{{ value }}</p>
                  <button
                    cdkCopyToClipboard
                    [cdkCopyToClipboard]="value"
                    matTooltip="{{ tooltipMessage }}"
                    [matTooltipDisabled]="tooltipDisabled"
                    (click)="onCopy()"
                    aria-label="Copy to clipboard"
                    (mouseover)="onHover()"
                    (mouseleave)="resetIconColor()"
                    [ngClass]="{ 'active': isClicked, 'copied': isCopied }"
                  >
                    <mat-icon>content_copy</mat-icon>
                  </button>

                </div>
              </div>

              <p>Concepto de transferencia: <strong>{{ name }} - {{ randomNumber }} </strong></p>

            </div>
            <div class="account-info">
              Total de Orden:

              <p> <strong>{{ granTotal |currency }}</strong></p>
            </div>
            <br>
            <p>
              Por favor, en el concepto de la transferencia, agrega tu nombre seguido del número de 6 dígitos para identificar el pago.
            </p>
            <p>
            <!--p>Ejemplo de concepto de transferencia: <strong>{{ name }} - {{ randomNumber }} </strong></p-->
            <i>Nota: Si el concepto es demasiado extenso para tu banco, redúcelo y añade el número: <strong> {{ randomNumber }} </strong></i>
            <p></p>
            <p>Una vez realizada la transferencia, carga la captura o envíanosla por WhatsApp al 8110760979. </p>
            <div>
              <a href="https://api.whatsapp.com/send?phone=528110760979&text=&source=&data=" target="_blank">
                <i class="fab fa-whatsapp" style="font-size: 2em; color: #25D366;"  matTooltip="Envianos tu comprobante"></i>
              </a>
            </div>
            <p>Finalmente, haz clic y confirma tu pedido.</p>
          </mat-card-content>
          <!--mat-card-actions>
            <button mat-button>LIKE</button>
            <button mat-button>SHARE</button>
          </mat-card-actions-->
        </mat-card>

        <!--div class="file-upload-container" *ngIf="selected === 'Transferencia'">
          <input type="file" (change)="onFileSelected($event)">
        </div-->







        <!--div class="upload-container" *ngIf="selected === 'Transferencia'">
          <input type="file" (change)="onFileSelected($event)" />
          <button type="button" class="btn btn-bg " (click)="uploadImage()" [disabled]="!selectedFile">Cargar imagen</button>
          <div *ngIf="imageUrl">
            <p>Image uploaded successfully!</p>
            <img [src]="imageUrl" alt="Uploaded Image" />
          </div>
        </div-->

        <!--div class="upload-container" *ngIf="selected === 'Transferencia'">
          <input type="file" (change)="onFileSelected($event)" />
          <button type="button" class="btn-icon" (click)="uploadImage()" [disabled]="!selectedFile">
            <mat-icon>cloud_upload</mat-icon>
          </button-->
          <!--mat-progress-bar *ngIf="uploading" mode="indeterminate"></mat-progress-bar-->
          <!--div *ngIf="imageUrl">
            <img [src]="imageUrl" alt="Uploaded Image" />
          </div>
        </div-->


        <!--div class="upload-container" *ngIf="selected === 'Transferencia'">
          <input type="file" #fileInput (change)="onFileSelected($event)" style="display: none;" />

          <button type="button" class="btn-icon" (click)="fileInput.click()">
            <mat-icon>cloud_upload</mat-icon>
          </button>

          <button type="button" class="btn-icon" (click)="uploadImage()" [disabled]="!selectedFile">
            <mat-icon>send</mat-icon>
          </button>

          <mat-progress-bar *ngIf="uploading" mode="indeterminate"></mat-progress-bar>

          <div *ngIf="imageUrl">
            <img [src]="imageUrl" alt="Uploaded Image" />
          </div>
        </div-->

        <!--div class="upload-container" *ngIf="selected === 'Transferencia'">
          <input type="file" #fileInput (change)="onFileSelected($event)" style="display: none;" />

          <button type="button" class="btn-icon" (click)="fileInput.click()">
            <mat-icon>cloud_upload</mat-icon>
          </button>

          <mat-progress-bar *ngIf="uploading" mode="indeterminate"></mat-progress-bar>

          <div *ngIf="imageUrl">
            <img [src]="imageUrl" alt="Uploaded Image" />
          </div>
        </div-->
        <div class="upload-container" *ngIf="selected === 'Transferencia'">
          <p>Da clic en el icono y carga tu comprobante</p>
          <input type="file" #fileInput (change)="onFileSelected($event)" style="display: none;" />

          <div class="icon-container" (click)="fileInput.click()" matTooltip="Da clic y elecciona tu comprobante de pago" matTooltipPosition="above">
            <mat-icon class="upload-icon">cloud_upload</mat-icon>
          </div>

          <mat-progress-bar *ngIf="uploading" mode="indeterminate"></mat-progress-bar>

          <div *ngIf="imageUrl">
            <img [src]="imageUrl" alt="Uploaded Image" />
          </div>
        </div>






        <!--div class="my-3" *ngIf="selected === 'Transferencia'">

          <button type="button"class="btn btn-danger" (click)="regresar()" style="background-color: #ff4d4d; color: white; border: none; cursor: pointer; transition: background-color 0.3s ease;">
            Regresar
          </button>

          <button type="button" class="btn btn-bg " (click)="confirmPedido()">
            Confirmar pedido
          </button>
        </div-->


        <div class="my-3" *ngIf="selected === 'Transferencia'">
          <button type="button" class="btn btn-danger" (click)="regresar()" [disabled]="isProcessingOrder"
                  style="background-color: #ff4d4d; color: white; border: none; cursor: pointer; transition: background-color 0.3s ease;">
            Regresar
          </button>

          <button type="button" class="btn btn-bg" (click)="confirmPedido()" [disabled]="isProcessingOrder">
            <span *ngIf="!isProcessingOrder">Confirmar pedido</span>
            <span *ngIf="isProcessingOrder">
      <i class="fa fa-spinner fa-spin"></i> Procesando...
    </span>
          </button>
        </div>



      </div>
      <div class="col-md-4">

      </div>
    </div>
</div>

