import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {UserService} from '../../../service/UserService';

@Component({
  selector: 'app-checkout-form',
  templateUrl: './checkout-form.component.html',
  styleUrls: ['./checkout-form.component.scss']
})
export class CheckoutFormComponent implements OnInit {
  hide = true;
  token: string | null = null;
  error = "";

  constructor(private router: Router,
              private userService: UserService) { }

  ngOnInit(): void {
    this.token = localStorage.getItem('token');
    console.log('token en checkout:', this.token);
    if(this.token != null){
        this.validationLogin(this.token);
    }

  // Cargar datos del localStorage en el formulario de dirección
      const savedAddress = localStorage.getItem('addressCheckOut');
      if (savedAddress) {
        const address = JSON.parse(savedAddress);
        this.addressCheckOut.patchValue(address);
        console.log('Dirección recuperada del localStorage:', address);
      }
  }

  contactForm = new FormGroup({
    email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });

  addressCheckOut = new FormGroup({
    name: new FormControl('',Validators.required),
    phone: new FormControl('',Validators.required),
    street: new FormControl('',Validators.required),
    number: new FormControl('',Validators.required),
    colony: new FormControl('',Validators.required),
    municipality: new FormControl('',Validators.required),
    state: new FormControl('',Validators.required),
    zipCode: new FormControl('',Validators.required),
    country: new FormControl('',Validators.required)
    });

//---registro y login

  onLogin(): void {
     const { email, password } = this.contactForm.value;

      // Asegúrate de que email y password sean strings
           const emailStr = email || '';
           const passwordStr = password || '';

    this.userService.login(emailStr, passwordStr)
      .then(response => {
        console.log('Login successful', response);
        localStorage.setItem('token', response.data.token);
        //this.router.navigate(['/secure-checkout']);
        this.router.navigate(['/secure-checkout']).then(() => {
                  window.location.reload(); // Recarga la página
                });
      })
      .catch(error => {
        console.error('Login error', error);
        this.contactForm.reset();
        this.error = "Usuario o contraseña incorrecto";
      })
  }

   register(): void {
    const { email, password } = this.contactForm.value;

    // Asegúrate de que email y password sean strings
    const emailStr = email || '';
    const passwordStr = password || '';

    this.userService.register(emailStr, passwordStr)
          .then(response => {
            console.log('Login successful', response);
            localStorage.setItem('token', response.data.token);
            //this.router.navigate(['/secure-checkout']);
            this.router.navigate(['/secure-checkout']).then(() => {
                      window.location.reload(); // Recarga la página
                    });
          })
          .catch(error => {
             console.error('Register error', error);
            this.contactForm.reset();
            this.error = "Ya se encuentra registrada esa cuenta.";
          })

   }


  private getErrorMnsFromError(err : any) {
    if (err && err.error) {
      let errorObject: any;
      if (typeof err.error == 'string') {
        try {
          errorObject = JSON.parse(err.error);
        } catch (ex) {
          // This is intentional
        }
      } else if (typeof err.error == 'object') {
        errorObject = err.error;
      }
      if (errorObject && errorObject.localizedMessage) {
        return errorObject.localizedMessage;
      }
    }
    return '';
  }

//Valida token

  validationLogin(token: string) {
    this.userService.validationLogin(token)
      .then(response => {
        console.log('Login successful', response);
         localStorage.setItem('cliente', JSON.stringify(response.data));

         const userStr = localStorage.getItem('cliente');
         if (userStr) {
           const user = JSON.parse(userStr);
           console.log('User guardado en localStorage:', userStr);
         }

        //this.router.navigate(['/home']);
      })
      .catch(error => {
         console.error('Login error', error);
         this.error = "No estas logeado";
      })
  }

  personalData(): void {

    const addressFormValue = this.addressCheckOut.value;
    const addressFormValueJson = JSON.stringify(addressFormValue);
    localStorage.setItem('addressCheckOut', addressFormValueJson);

    console.log('addressCheckOut: ', addressFormValueJson);
    this.router.navigate(['/payments']);
   }

  regresar(): void {
    this.router.navigate(['/cart']);
   }

}
