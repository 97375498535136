<nav class="navbar navbar-expand-lg navbar-light shadow-sm animated fadeInDown" id="navbar"
  (scroll)="onWindowScroll($event);">
  <div class="container  navbar-expand-sm">
    <a class="navbar-brand" routerLink="/">
      <img src="../../../assets/camben.png" alt="" height="40" class="d-inline-block align-text-top">
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
      <span class="navbar-toggler-icon"></span>
    </button>
    <!--div class="collapse navbar-collapse" id="navbarSupportedContent"-->
    <div [ngClass]="{'collapse': !isMenuOpen, 'navbar-collapse': true}" id="navbarNav">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <!--li class="nav-item">
          <a class="nav-link text-capitalize" routerLink="/home" routerLinkActive="active" ariaCurrentWhenActive="page">home</a>
        </li-->
        <li class="nav-item">
          <a class="nav-link text-capitalize" routerLink="/product" routerLinkActive="active" ariaCurrentWhenActive="page">shop</a>
        </li>
        <!--li class="nav-item">
          <a class="nav-link text-capitalize" routerLink="/blog" routerLinkActive="active" ariaCurrentWhenActive="page">blog</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-capitalize" routerLink="/vendor" routerLinkActive="active" ariaCurrentWhenActive="page">vendor</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-capitalize" routerLink="/contact" routerLinkActive="active" ariaCurrentWhenActive="page">contact</a>
        </li-->
        <li class="nav-item">
          <a class="nav-link text-capitalize" routerLink="/login" routerLinkActive="active" ariaCurrentWhenActive="page">login</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-capitalize" routerLink="/pedidos" routerLinkActive="active" ariaCurrentWhenActive="page">pedidos</a>
        </li>
      </ul>
      <ul class="mb-0">

        <!-- <span class="icon">
          <a href="#" class="text-decoration-none btn-color"><i class="bi bi-heart" routerLink="/checkout"></i></a></span> -->
        <span class="cart-count btn-color">
          <i class="bi bi-cart" routerLink="/cart"></i>
          <em>{{cartProductCount}}</em>
        </span>
        <!-- <li>login</li> -->
      </ul>
    </div>
  </div>
</nav>


<!--nav class="navbar navbar-expand-sm navbar-light bg-light">
  <div class="container">
    <a class="navbar-brand" href="#">Brand</a>
    <button class="navbar-toggler" type="button" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div [ngClass]="{'collapse': !isMenuOpen, 'navbar-collapse': true}" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" routerLink="/home" routerLinkActive="active">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/vendor" routerLinkActive="active">vendor</a>
        </li>

        <li class="nav-item">
          <a class="nav-link text-capitalize" routerLink="/home" routerLinkActive="active" ariaCurrentWhenActive="page">home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-capitalize" routerLink="/product" routerLinkActive="active" ariaCurrentWhenActive="page">shop</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-capitalize" routerLink="/blog" routerLinkActive="active" ariaCurrentWhenActive="page">blog</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-capitalize" routerLink="/vendor" routerLinkActive="active" ariaCurrentWhenActive="page">vendor</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-capitalize" routerLink="/contact" routerLinkActive="active" ariaCurrentWhenActive="page">contact</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-capitalize" routerLink="/login" routerLinkActive="active" ariaCurrentWhenActive="page">login</a>
        </li>

      </ul>
    </div>
  </div>
</nav-->
