<div class="container mt-5 mb-5">
  <div class="card border-0">
    <div class="row g-0" *ngIf="product">
      <div class="col-md-6 border-end">
        <div class="d-flex flex-column justify-content-center">
          <!--div class="main_image">
            <img src={{product.coverImage}} #someInput id="idd" width="350">
          </div-->
          <div class="thumbnail_images">
            <!--ul id="thumbnail">
                <li><img src="https://i.imgur.com/TAzli1U.jpg" width="270"></li>
                <li><img  src="https://i.imgur.com/w6kEctd.jpg" width="270"></li-->
            <!--li><img (click)="changeImage(this)" src="https://i.imgur.com/L7hFD8X.jpg" width="70"></li>
            <li><img (click)="changeImage(this)" src="https://i.imgur.com/6ZufmNS.jpg" width="70"></li-->
            <!--/ul-->

            <!--div class="gallery-list">
              <div *ngFor="let album of albums; let i = index" class="gallery-item">
                <img [src]="album.thumb" (click)="open(i)" class="img-thumbnail" />
                <p>{{ album.caption }}</p>
              </div>
            </div-->


            <div class="gallery-list">
              <div *ngFor="let album of albums; let i = index"
                   class="gallery-item"
                   (mouseenter)="hoverImage(true)"
                   (mouseleave)="hoverImage(false)"
                   (click)="selectImage(i)"
                   [class.selected]="selectedImage?.src === album.thumb"
                   class="gallery-item">
                <img [src]="album.thumb" (mouseenter)="selectImage(i)" class="img-thumbnail" />
                <p>{{ album.caption }}</p>
              </div>
            </div>

            <div *ngIf="selectedImage" class="selected-image">
              <img [src]="selectedImage?.src" class="img-large" />
              <p>{{ selectedImage?.caption }}</p>
            </div>

          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="p-3 right-side">
          <div class="d-flex justify-content-between align-items-center">
            <h1>{{ product.product }}</h1>
            <span class="heart">
              <i class='bx bx-heart'></i>
            </span>
          </div>
          <h4>Modelo: {{ product.model |titlecase }}</h4>
          <div class="mt-2 pr-3 content">
            <h4>Marca: {{ product.brand |titlecase }}</h4>
            <div class="ratings d-flex flex-row align-items-center">
              <div class="d-flex flex-row">
                <bar-rating [(rate)]="product.qualification" [max]="5" [readonly]="true"></bar-rating>
                <span>({{product.qualification | number: '1.1-1'}})</span>
              </div>
            </div>

            <br>
            <div  *ngIf="!selectedSize" class="price-display">
              <h3  class="dorado">Precio:  {{ product.minPrice |currency }}  - {{ product.maxPrice |currency }} </h3>
            </div>

            <div *ngIf="selectedSize" class="price-display">
              <h3  class="dorado">Precio: {{ selectedSize.price | currency }}</h3>
              <!--p *ngIf="selectedSize.promoPrice">Promo Price: {{ selectedSize.promoPrice | currency }}</p-->
            </div>

            <!--div>
              <h3>{{ product.priceShop |currency }}</h3>
            </div-->

            <!--p *ngIf="!selectedColor">Color:</p>
            <p *ngIf="selectedColor">Color: {{ selectedColor }}</p>


            <div class="color-selector">
              <div *ngFor="let color of colors"
                   class="color-box"
                   [style.backgroundColor]="color"
                   [class.selected]="color === selectedColor"
                   (click)="selectColor(color)">
              </div>
            </div-->
            <p *ngIf="!selectedColor">Seleciona el color:</p>
            <p *ngIf="selectedColor">
              Color: {{ selectedColor.name }}
            </p>


            <div class="color-selector">
              <div *ngFor="let color of colors"
                   class="color-box"
                   [style.backgroundColor]="color.hex"
                   [class.selected]="color === selectedColor"
                   (click)="selectColor(color)">
              </div>
            </div>


            <h3>Seleciona tu talla:</h3>

            <!--div class="size-options">
              <div *ngFor="let size of sizePriceLts"
                   (click)="selectSize(size)"
                   [class.selected]="size === selectedSize"
                   class="size-box">
                {{ size.size }}
              </div>
            </div-->


            <div class="size-options">
              <div class="row">
                <div *ngFor="let size of sizePriceLts; let i = index"
                     (click)="selectSize(size)"
                     [class.selected]="size === selectedSize"
                     class="col-3 size-box">
                  {{ size.size }}
                </div>
              </div>
            </div>



            <br>
            <div *ngIf="product.personalized" class="additional-info">

              <h3>Detalles adicionales para el personalizado:</h3>


              <div class="form-group">
                <label>Nombre de la persona</label>
                <input matInput class="form-control styled-input" [(ngModel)]="newSize.namePerson" placeholder="Nombre de la persona" />
              </div>
              <div class="form-group">
                <label>Cargo</label>
                <input matInput class="form-control styled-input" [(ngModel)]="newSize.position" placeholder="Puesto" />
              </div>
              <div class="form-group">
                <label>Tipo de sangre</label>
                <input matInput class="form-control styled-input" [(ngModel)]="newSize.bloodType" placeholder="Tipo de sangre" />
              </div>
              <div class="form-group">
                <label>Clase</label>
                <input matInput class="form-control styled-input" [(ngModel)]="newSize.claseInProgress" placeholder="Clase" />
              </div>
              <div class="form-group">
                <label>Nombre del club</label>
                <input matInput class="form-control styled-input" [(ngModel)]="newSize.club" placeholder="Nombre del club" />
              </div>
              <div class="form-group">
                <label>Años de servicio</label>
                <input matInput class="form-control styled-input" [(ngModel)]="newSize.yearsService" placeholder="Años de servicio" />
              </div>

            </div>


            <br>
            <br>

            <!--h3>Tabla de medidas:</h3-->

            <div class="measurement-table">
              <span class="toggle-text" (click)="openLightbox(lightbox)">

                <u style="color: #e94107;">Tabla de medidas</u>
                <i class="fas fa-chevron-down"></i>
              </span>
            </div>

            <ng-template #lightbox>
              <div class="lightbox-content">
                <img [src]="urlSize" alt="Tabla de medidas" />
              </div>
            </ng-template>

            <br>
            Cantidad:

            <div class="product-quantity">
              <!--label for="quantity">Cantidad:</label-->
              <div class="quantity-input">
                <button type="button" class="btn-quantity" (click)="decreaseQuantity()">-</button>
                <input type="number" id="quantity" [(ngModel)]="productQuantity" min="1" max="10000000000000" />
                <button type="button" class="btn-quantity" (click)="increaseQuantity()">+</button>
              </div>
            </div>


            <br>



            <h3>Detalle del producto</h3>
            <p><span [innerHTML]="product.detailProduct"></span></p>


            <div class="product">


            </div>

            <h3>Descripcion y especificacion:</h3>
            <p><span [innerHTML]="product.description"></span></p>


            <!--div class="buttons d-flex flex-row mt-5 gap-3">

              <button class="btn btn-bg" (click)='addToCart(product.id)'>Add to Cart</button>
              <button class="btn btn-light">
                <i class="bi bi-suit-heart-fill"></i>
                <span class="text">Add to wishlist</span>
              </button>
            </div-->
          </div>
        </div>
      </div>


      <div class="col-md-2">
        <div class="product-info">

          <div>

            <button type="button"class="btn btn-danger" (click)="regresar()" style="background-color: #ff4d4d; color: white; border: none; cursor: pointer; transition: background-color 0.3s ease;">
              Regresar
            </button>
          </div>

          <div  *ngIf="!selectedSize" class="price-display">
            <h4  class="black"> Para comprar, selecciona <strong>Talla</strong> </h4>
          </div>



          <div  *ngIf="!selectedSize" class="price-display">
            <h4  class="black"><strong>Precio:  {{ product.minPrice |currency }}  - {{ product.maxPrice |currency }}</strong></h4>
          </div>

          <div *ngIf="selectedSize" class="price-display">
            <h4  class="black"><strong>Precio: {{ selectedSize.price | currency }}</strong></h4>
            <!--p *ngIf="selectedSize.promoPrice">Promo Price: {{ selectedSize.promoPrice | currency }}</p-->
          </div>

          <!--p>Envío gratis a partir de <strong>$999*</strong></p-->
          <p class="text-center text-success fw-bold">¡Envío gratis en compras a partir de $899!</p>
          <!--p>Fecha estimada de entrega: <strong>el 15 de diciembre, Realizando el pedido el dia de hoy.</strong></p-->

          <!--p>Fecha estimada de entrega:
            <strong>el {{ createAtPlus5BusinessDays | date: 'dd MMMM' }} - {{ createAtPlus20BusinessDays | date: 'dd MMMM' }}, Realizando el pedido el día de hoy.</strong>
          </p-->

          <p>Fecha estimada de entrega:
            <strong>
              entre {{ createAtPlus5BusinessDays ? translateMonth(createAtPlus5BusinessDays) : 'fecha no disponible' }} y
              {{ createAtPlus20BusinessDays ? translateMonth(createAtPlus20BusinessDays) : 'fecha no disponible' }},
              Realizando el pedido el día de hoy.
            </strong>
          </p>


          <!--p>
            Se entregará a Ciudad Benito Juárez, 67277:
            <a href="#" class="update-location">actualizar ubicación</a>
          </p-->
          <p><strong>Disponible</strong></p>

          <div class="error-messages">
            <div *ngIf="showProductError" class="alert alert-danger">
              No hay producto seleccionado
            </div>
            <div *ngIf="showSizeError" class="alert alert-danger">
              Por favor, selecciona una talla
            </div>
            <!--div *ngIf="showColorError" class="alert alert-danger">
              Por favor, selecciona un color
            </div-->
          </div>

          <button class="btn btn-primary" (click)='addCart()'>Agregar al Carrito</button>
          <button class="btn btn-secondary" (click)='goToCar()'>Ir al carrito</button>

          <div class="shipping-info">
            <p><strong>Envío desde:</strong> Camben México</p>
            <p><strong>Vendido por:</strong> Camben México</p>
          </div>

          <!--div class="return-info">
            <p><strong>Devoluciones:</strong> Devolución sin costo durante 30 días a partir de que recibes el producto</p>
          </div-->

          <!--div class="payment-info">
            <p><strong>Pago:</strong> Transacción segura</p>
            <p><a href="#">Detalles</a></p>
          </div-->

          <!--p><a href="#" class="gift-options">Agregar opciones de regalo</a></p-->
        </div>
      </div>

    </div>
  </div>
</div>




<br>



<!--div class="video-container">
  <iframe
    width="560"
    height="315"
    [src]="safeUrl"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen>
  </iframe>
</div-->
