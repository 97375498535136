import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {ContactComponent} from './contact/contact.component';
import {BlogComponent} from './blog/blog.component';
import {ShoppingModule} from './shopping/shopping.module';
import {SharedModule} from './shared/shared.module';
import {LoginComponent} from './login/login.component';
import { CheckoutFormComponent } from './shared/components/checkout-form/checkout-form.component';
import { PaymentsComponent } from './payments/payments.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {getAuth, provideAuth} from '@angular/fire/auth';
import {getFirestore, provideFirestore} from '@angular/fire/firestore';
import {NgbRatingModule} from '@ng-bootstrap/ng-bootstrap';
import {MatIconModule} from '@angular/material/icon';
import {BarRatingModule} from 'ngx-bar-rating';
import {NavbarsComponent} from './shared/components/navbars/navbars.component';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {MatInputModule} from '@angular/material/input';
import {NgbAccordionModule} from '@ng-bootstrap/ng-bootstrap';
import {MatCardModule} from '@angular/material/card';
import {MatSelectModule} from '@angular/material/select';
import { LightboxModule } from 'ngx-lightbox';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { CommonModule } from '@angular/common';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatLabel } from '@angular/material/form-field';

//registerLocaleData(localeEs);

@NgModule({
  declarations: [
    AppComponent,
    ContactComponent,
    BlogComponent,
    LoginComponent,
    CheckoutFormComponent,
    PaymentsComponent,
    DashboardComponent,
    OrderDetailsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ShoppingModule,
    SharedModule,
    BarRatingModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    NgbRatingModule,
    MatIconModule,
    MatInputModule,
    NavbarsComponent,
    NgbAccordionModule,
    MatCardModule,
    MatSelectModule,
    LightboxModule,
    ClipboardModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatTableModule,
    CommonModule,
    MatProgressSpinnerModule,
    MatLabel
  ],
  providers: [
   // { provide: LOCALE_ID, useValue: 'es' },
    provideHttpClient(withInterceptorsFromDi()),
    provideFirebaseApp(() =>
      initializeApp({
        projectId: 'camben-ecommerce',
        appId: '1:603348347646:web:7b816a2f6e6b919fca93af',
        storageBucket: 'camben-ecommerce.appspot.com',
        apiKey: 'AIzaSyCNMevR5gfnIz9m7qxeF7ogs757EQqcgKQ',
        authDomain: 'camben-ecommerce.firebaseapp.com',
        messagingSenderId: '603348347646',
        measurementId: 'G-3FKHF9JNWK',
      })
    ),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideAnimationsAsync(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
