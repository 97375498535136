import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

import {Router} from '@angular/router';
import {UserService} from '../service/UserService';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  hide = true;
  error = "";
  contador = 0;


  showForgotPassword = false;
  loading = false;
  message = '';

  textColor: string = 'blue';
  email: string = '';

  constructor(private router: Router,
              private userService: UserService) { }

  ngOnInit(): void {

  }

  contactForm = new FormGroup({
    email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });


  toggleForgotPassword(): void {
    this.showForgotPassword = !this.showForgotPassword;
  }

  resetPassword() {
    if (!this.email) {
      alert('Por favor, ingrese su correo electrónico.');
      return;
    }

    this.loading = true;
    this.userService.resetPassword(this.email).subscribe(
      (response: any) => {
        this.loading = false;
        this.message = 'Enlace de restablecimiento enviado a su correo electrónico.';
      },
      (error) => {
        this.loading = false;
        console.error('Error:', error);
        this.message = 'Hubo un error al intentar restablecer la contraseña.';
      }
    );
  }


  onLogin(): void {
    //this.contador++;
    //localStorage.setItem("userID", <string><unkno wn>this.contador);
    //if (<number><unknown>(localStorage.getItem("userID")) < 4) {
    // if (this.contactForm.valid) {

     const { email, password } = this.contactForm.value;

      // Asegúrate de que email y password sean strings
           const emailStr = email || '';
           const passwordStr = password || '';


    this.userService.login(emailStr, passwordStr)
      .then(response => {
        console.log('Login successful', response);
        this.validationLogin(response.data.token);
        localStorage.setItem('token', response.data.token);
        this.router.navigate(['/product']);
      })
      .catch(error => {
         console.error('Login error', error);
        this.contactForm.reset();
        this.error = "Usuario o contraseña incorrecto";
      })

    //    localStorage.removeItem("userID");
    // const formValue = this.contactForm.value;
    /* this.loginSvc.login(formValue).then(res => {
       if (res) {
         this.router.navigate(['home']);
       }
     }).catch(err => {
     //  this.nombre = null;
     //  this.contrasena = null;
       this.error = this.getErrorMnsFromError(err);
       if (err.status == 401 && !this.error) {
         this.error = 'Nombre de usuario o contraseña incorrectos';
       }
     })*/
    // }
    /*} else {
      this.nombre = null;
      this.contrasena = null;
      this.error = 'Acceso denegado';
    }*/
  }

   register(): void {
    const { email, password } = this.contactForm.value;

    // Asegúrate de que email y password sean strings
    const emailStr = email || '';
    const passwordStr = password || '';

    this.userService.register(emailStr, passwordStr)
          .then(response => {
            console.log('Login successful', response);

            this.validationLogin(response.data.token);

            localStorage.setItem('token', response.data.token);
            this.router.navigate(['/home']);

          })
          .catch(error => {
             console.error('Register error', error);
            this.contactForm.reset();
            this.error = "Ya se encuentra registrada esa cuenta.";
          })

   }

 validationLogin(token: string) {
     this.userService.validationLogin(token)
       .then(response => {
         console.log('Login successful', response);
          localStorage.setItem('cliente', JSON.stringify(response.data));

          const userStr = localStorage.getItem('cliente');
          if (userStr) {
            const user = JSON.parse(userStr);
            console.log('User guardado en localStorage:', userStr);
          }

         //this.router.navigate(['/home']);
       })
       .catch(error => {
          console.error('Login error', error);
          this.error = "No estas logeado";
       })
   }


  private getErrorMnsFromError(err : any) {
    if (err && err.error) {
      let errorObject: any;
      if (typeof err.error == 'string') {
        try {
          errorObject = JSON.parse(err.error);
        } catch (ex) {
          // This is intentional
        }
      } else if (typeof err.error == 'object') {
        errorObject = err.error;
      }
      if (errorObject && errorObject.localizedMessage) {
        return errorObject.localizedMessage;
      }
    }
    return '';
  }

}
