<section class="py-5">
  <div class="row">
    <h1 *ngIf="checkoutProduct.length>0">Carrito</h1>
    <div class="col-lg-8">
      <ng-container *ngIf="checkoutProduct.length>0">
        <div class="table-wrap mt-3">
          <table class="table table-responsive table-borderless">
            <thead>
            <th>Imagen</th>
            <th>Producto</th>
            <!--th>Precio</th>
            <th>Cantidad</th-->
            <th>Eliminar</th>
            <!--th>total</th-->
            <th>&nbsp;</th>
            </thead>
            <tbody>
            <tr class="align-middle alert border-bottom" role="alert"
                *ngFor="let item  of checkoutProduct">

              <td class="text-center">

                  <img class="pic" src={{item.coverImage}} alt="" (click)="goToProductDetails(item)">

              </td>
              <td>
                <div>
                  <div (click)="goToProductDetails(item)">
                    <p class="m-0 fw-bold">{{ item?.product }}</p>
                    <p class="m-0 text-muted">{{ item?.category }}</p>
                    <p class="m-0 fw-bold">Color: {{ item?.selectedColor.name }}</p>
                    <p class="m-0 text-muted">Talla: {{ item?.selectedSize.size }}</p>
                    <div *ngIf="item.personalized">
                      <p class="m-0 text-muted">Nombre: {{ item?.selectedSize.namePerson }}</p>
                      <p class="m-0 text-muted">Puesto: {{ item?.selectedSize.position }}</p>
                      <p class="m-0 text-muted">Tipo de sangre: {{ item?.selectedSize.bloodType }}</p>
                      <p class="m-0 text-muted">Clase: {{ item?.selectedSize.claseInProgress }}</p>
                      <p class="m-0 text-muted">Nombre del club: {{ item?.selectedSize.club }}</p>
                      <p class="m-0 text-muted">Años de servicio: {{ item?.selectedSize.yearsService }}</p>
                    </div>
                  </div>

                  <br>
                  <div class="fw-600">{{ item.selectedSize.price |currency }}</div>
                  <br>
                  <td>
                    <div class="input-group input-spinner">
                      <div class="input-group-prepend">
                        <button class="btn btn-light" type="button" (click)="decreaseQuantity(item)">
                          <i class="bi bi-dash"></i>
                        </button>
                      </div>
                      <input type="text" class="form-control" [value]="item.quantity" (input)="onQuantityChange(item, $event)">
                      <div class="input-group-append">
                        <button class="btn btn-light" type="button" (click)="increaseQuantity(item)">
                          <i class="bi bi-plus"></i>
                        </button>
                      </div>
                    </div>
                  </td>

                </div>
              </td>
              <!--td>
                <div class="fw-600">{{ item.selectedSize.price |currency }}</div>
              </td-->
              <!--td>
                <div class="input-group input-spinner">
                  <div class="input-group-prepend">
                    <button class="btn btn-light" type="button" id="button-plus"><i class="bi bi-dash"></i></button>
                  </div>
                  <input type="text" class="form-control" value="1">
                  <div class="input-group-append">
                    <button class="btn btn-light" type="button" id="button-minus"><i class="bi bi-plus"></i></button>
                  </div>
                </div>
              </td-->



              <!--td> {{ calculateTotal(item) | currency }}</td-->

              <!--td> {{ total |currency }}</td-->
              <td>
                <div class="btn" data-bs-dismiss="alert"><i class="bi bi-trash"
                                                            aria-label="Remove Item" type="button"
                                                            (click)="removeItem(item.idTable)"></i>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </ng-container>

      <ng-container *ngIf="checkoutProduct.length===0">
        <h3>Carrito vacio</h3>
        <div class="col-4 my-auto py-4 mx-auto">
          <img src="../../assets/undraw_empty_cart_co35.svg" class="img-fluid" alt="">
        </div>
      </ng-container>

    </div>
    <div class="col-lg-4 p-3">
      <app-order-total [total]="total" [envio]="envio" [granTotal]="granTotal" [productCount]="productCount"></app-order-total>
    </div>
  </div>
</section>

<div class="container">

</div>
