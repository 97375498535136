import { Component, OnInit } from '@angular/core';
import { DataService } from '../../service/data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  public checkoutProduct: any = [];
  public total: any;
  public envio: number = 285;  // Costo de envío inicial
  public granTotal: number = 0;
  public productCount: number = 0; // Propiedad para el total de productos

  constructor(private data: DataService,
              private router: Router) {
    //this.envio = 350;
    }

  ngOnInit(): void {
    this.checkoutProduct = this.data.getCart(); // Cargar el carrito desde localStorage
    this.updateTotalPrice(); // Calcular el precio total basado en el carrito cargado
    //this.total = this.data.getTotalPrice();
    //this.checkoutProduct = this.data.getCart();
    this.getProductsCounts();

    //this.checkoutProduct = this.data.getCart();
    this.total = this.data.getTotalPrice();
    this.calculateGrandTotal();
    //this.getProductsCounts();

     // Verifica qué productos se están obteniendo
      console.log('Initial checkoutProduct:', this.checkoutProduct);


    // Subscripción a cambios en el carrito
    this.data.getProductsCount().subscribe((res: any) => {
      this.checkoutProduct = res;
      this.updateTotalPrice(); // Actualiza el total cuando hay cambios

      console.log('Updated checkoutProduct:', this.checkoutProduct);

    });

  }
//calcula
  getProductsCounts(): void {
    this.data.getProductsCount().subscribe((res: any) => {
      this.total = this.data.getTotalPrice();
      this.granTotal = this.envio + this.total;
      this.checkoutProduct = res;
      this.productCount = this.checkoutProduct.reduce((acc: number, item: any) => acc + item.quantity, 0); // Contar productos
 console.log('contador de producto:', this.productCount);
    });
  }

  /*removeItem(productId: string): void {
    this.data.removeItemFromCheckOut(productId);
  }*/

//modificaciones de carrito de compra

  updateTotalPrice(): void {
    this.total = this.checkoutProduct.reduce((acc: number, item: any) => acc + (item.selectedSize.price * item.quantity), 0);
    this.productCount = this.checkoutProduct.reduce((acc: number, item: any) => acc + item.quantity, 0); // Actualizar el total de productos
    console.log('contador de producto:', this.productCount);
    this.calculateGrandTotal();
  }

  increaseQuantity(item: any): void {
    if (item.quantity < item.selectedSize.stock) {
      item.quantity++;
      this.updateItem(item);
    }
  }

  decreaseQuantity(item: any): void {
    if (item.quantity > 1) {
      item.quantity--;
      this.updateItem(item);
    }
  }

onQuantityChange(item: any, event: Event): void {
  const inputElement = event.target as HTMLInputElement;
  let quantity = parseInt(inputElement.value);

  if (isNaN(quantity) || quantity < 1) {
    quantity = 1;
  } else if (quantity > item.selectedSize.stock) {
    quantity = item.selectedSize.stock;
  }

  item.quantity = quantity;
  this.updateItem(item);
}


  updateItem(item: any): void {
    // Actualizar el carrito en el servicio
    this.data.updateCartItem(item);
    console.log('item: ', item);
    // Actualizar el precio total
    this.updateTotalPrice();

  }

  calculateTotal(item: any): number {
    return item.selectedSize.price * item.quantity;
  }

  /*calculateGrandTotal(): void {
    this.granTotal = this.total + this.envio;
  }*/

  calculateGrandTotal(): void {
    if (this.total >= 899) {
      this.envio = 0;
    } else {
      this.envio = 285;
    }
    this.granTotal = this.total + this.envio;
    localStorage.setItem('granTotal', JSON.stringify(this.granTotal));
    // Guardar el subtotal en localStorage
    localStorage.setItem('subtotal', JSON.stringify(this.total));
    localStorage.setItem('envio', JSON.stringify(this.envio));

  }

  removeItem(productId: string): void {
    this.data.removeItemFromCheckOut(productId);
    this.checkoutProduct = this.data.getCart(); // Actualiza la lista de productos en el carrito
    this.updateTotalPrice(); // Actualiza el precio total después de eliminar
  }

// Método para navegar a los detalles del producto

  goToProductDetails(product: any): void {
    localStorage.setItem('selectedProduct', JSON.stringify(product));
    console.log('producto del carrito con el click:', product);
    this.router.navigate(['/product/', product.id]);
  }

}
