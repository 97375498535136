<div class="row">
  <ng-container *ngIf="productList && productList.length > 0">
    <div
      class="col-lg-4 col-md-6 mb-3"
      *ngFor="
        let item of productList
          | filter : searchKey : 'product'
          | paginate : { itemsPerPage: 9, currentPage: p }
      "
    >
      <div class="card border-0 p-3 h-100">
        <div (click)="viewProductDetail(item)">
          <img
            src="{{ item.coverImage }}"
            class="card-img-top img-fluid"
            alt="{{ item.product }}"
          />
          <div class="card-body">
            <a class="product-meta d-block fs-xs pb-1">{{ item.category }}</a>
            <h5 class="card-title text-truncate">{{ item.product }}</h5>
            <!--p class="card-text text-truncate">{{ item.description }}</p-->
            <!--p class="card-text text-truncate"><span [innerHTML]="item.description"></span></p-->
            <p class="card-text"><span [innerHTML]="getTruncatedDescription(item.description)"></span></p>
            <div class="d-flex justify-content-between">
              <div class="product-price">
                <span class="text-accent price h5">{{
                  item.priceShop | currency
                }}</span>
                <del class="price-old" *ngIf="item.undiscountedPrice > item.priceShop">
                  {{ item.undiscountedPrice | currency }}
                </del>
              </div>
              <!-- <div class="star-rating">
                <bar-rating [(rate)]="item.rating.rate" [max]="5" [readonly]="true"></bar-rating>
                <span>({{item.rating.count}})</span>
              </div> -->
            </div>
          </div>
        </div>
        <!--div class="card-footer">
          <button
            type="button"
            class="btn btn-bg d-block w-100"
            (click)="addToCart(item.id)"
          >
            <i class="bi bi-cart-plus"></i>
            Add to cart
          </button>
        </div-->
      </div>
    </div>
  </ng-container>
</div>
<div class="col-md-4 mx-auto my-4">
  <ng-container *ngIf="productList && productList.length > 0">
    <pagination-controls (pageChange)="p = $event"></pagination-controls>
  </ng-container>
</div>
