import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Product} from 'src/app/shared/models/product';
import {DataService} from 'src/app/service/data.service';
import {Category} from "../../models/category";

@Component({
  selector: 'app-cat-sidebar',
  templateUrl: './cat-sidebar.component.html',
  styleUrls: ['./cat-sidebar.component.scss']
})
export class CatSidebarComponent implements OnInit {

  public catagoryList: Category[] = [];
  public productList: any = [];
  @Input() inputMin: number = 0;
  @Input() inputMax: any;
  @Input() priceRangeChange: any;
  @Output() rangeChange = new EventEmitter<any>();
  @Output() GetCategory = new EventEmitter<any>();
  @Output() MinMaxPrice = new EventEmitter<any>();
  searchTerm !: string;
  minPrice: number = 0;
  maxPrice: number = 0;

  constructor(private data: DataService) {
  }

  ngOnInit(): void {
    this.data.getAllCategory().subscribe((res: any) => {
      this.catagoryList = res;
      this.countAllCategories()
    });
  }

  isSelected(cat: Category) {
    if (cat.category == 'All Products') {
      this.data.getAllProductList().subscribe((res: any) => {
        this.GetCategory.emit(res);
      });
    } else {
      this.data.getInCategory(cat.category).subscribe((res: any) => {
        this.GetCategory.emit(res);
      });
    }

  }

  onRangeChange(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target) {
      this.rangeChange.emit(target.value);
    }
  }

  countAllCategories(): void {
    const countAllCategories = this.catagoryList.reduce((sum, cat) => sum + cat.count, 0);
    this.catagoryList.push({id: '0', category: 'All Products', count: countAllCategories});
  }

  search(event: any) {
    this.searchTerm = (event.target as HTMLInputElement).value;
    this.data.search.next(this.searchTerm);
  }

  onInputChangeMinPriceRange(newValue: number) {
    this.minPrice = newValue;
  }

  onInputChangeMaxPriceRange(newValue: number) {
    this.maxPrice = newValue;
  }

  filterPriceRange() {
    this.data.getPriceRange(this.minPrice, this.maxPrice).subscribe((res: Product) => {
      this.MinMaxPrice.emit(res);
    });
  }

}

