import { Component, OnInit  } from '@angular/core';
import { Order } from '../shared/models/order';
import {Router} from '@angular/router';
import { DataService } from '../service/data.service';
import {UserService} from '../service/UserService';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {
  hide = true;
  token: string | null = null;
  error = "";

    orders: Order[] = [];

      constructor(private router: Router, private data: DataService, private userService: UserService) { }

 contactForm = new FormGroup({
    email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });

      ngOnInit(): void {

         this.refresh();
      }
    //cambia de idioma los meses
    translateDate(dateString: string | null | undefined): string {
      if (!dateString) return '';  // Manejar caso de null o undefined

      const date = new Date(dateString);

      return new Intl.DateTimeFormat('es-ES', { day: '2-digit', month: 'long', year: 'numeric' }).format(date);
    }

    refresh(): void {

       this.token = localStorage.getItem('token');
          console.log('token en checkout:', this.token);
          if(this.token != null){
              this.validationLogin(this.token);
          }

        const userStr = localStorage.getItem('cliente');
         if (userStr) {
           const user = JSON.parse(userStr);
           console.log('User guardado en localStorage:', userStr);
         }

        const cliente = JSON.parse(localStorage.getItem('cliente') || '{}');

                const user = cliente.uid;
                const email = cliente.email;

                this.data.searchOrders(user, email).subscribe(
                  (data: Order[]) => {
                    this.orders = data;
                   // this.dataSource = this.orders;
                    console.log('Orders:', this.orders);
                  },
                  error => {
                    console.error('Error fetching orders:', error);
                  }
                );
      }

/*viewOrderDetails(order: Order): void {
    if (order) {
      this.router.navigate(['/order-details'], {
        state: { order }  // Enviando el objeto completo del pedido
      });
      console.error('Orden enviada', order);
    } else {
      console.error('Order is undefined');
    }
  }*/



viewOrderDetails(order: Order): void {
    if (order) {
      this.data.setOrder(order);
      this.router.navigate(['/order-details']);
    } else {
      console.error('Order is undefined');
    }
  }


  validationLogin(token: string) {
    this.userService.validationLogin(token)
      .then(response => {
        console.log('Login successful', response);
         localStorage.setItem('cliente', JSON.stringify(response.data));
           this.refresh();

      })
      .catch(error => {
         console.error('Login error', error);
         this.error = "No estas logeado";
      })
  }



//---registro y login

  onLogin(): void {
     const { email, password } = this.contactForm.value;

      // Asegúrate de que email y password sean strings
           const emailStr = email || '';
           const passwordStr = password || '';

    this.userService.login(emailStr, passwordStr)
      .then(response => {
        console.log('Login successful', response);
        localStorage.setItem('token', response.data.token);
         this.refresh();
        //this.router.navigate(['/pedidos']);
              // Refrescar la página completa y redirigir a la sección de pedidos
        window.location.href = '/pedidos';

        //this.router.navigate(['/secure-checkout']);

      })
      .catch(error => {
        console.error('Login error', error);
        this.contactForm.reset();
        this.error = "Usuario o contraseña incorrecto";
      })
  }

   register(): void {
    const { email, password } = this.contactForm.value;

    // Asegúrate de que email y password sean strings
    const emailStr = email || '';
    const passwordStr = password || '';

    this.userService.register(emailStr, passwordStr)
          .then(response => {
            console.log('Login successful', response);
            localStorage.setItem('token', response.data.token);

             this.refresh();

            //this.router.navigate(['/secure-checkout']);
            this.router.navigate(['/pedidos']).then(() => {
                      window.location.reload(); // Recarga la página
                    });
          })
          .catch(error => {
             console.error('Register error', error);
            this.contactForm.reset();
            this.error = "Ya se encuentra registrada esa cuenta.";
          })

   }
}
